import React, { useState, useEffect, useRef } from "react";
import { Box, TextField } from "@mui/material";
import { useMapsLibrary } from "@vis.gl/react-google-maps";

export default function AddressAutocomplete(props) {
  const { onPlaceSelect } = props;
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);

  const [query, setQuery] = useState(props.value?.description || "");
  const [place, setPlace] = useState("");

  const inputRef = useRef(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      types: ["address"],
      componentRestrictions: { country: "us" },
      fields: ["geometry", "name", "address_components"]
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      const o = placeAutocomplete.getPlace();
      const address = getAddress(o.address_components);

      const coordinates = { lat: o.geometry.location.lat(), lng: o.geometry.location.lng() };

      setPlace(address);

      const description = getDescription(address);
      setQuery(description);

      onPlaceSelect({ ...address, coordinates, description });
    });
  }, [onPlaceSelect, placeAutocomplete]);

  const getDescription = (o) => {
    return `${o.street}, ${o.city} ${o.state} ${o.zip}`;
  };

  const getAddress = (address_components) => {
    let [street, neighborhood, sublocality, locality, state, zip] = ["", "", "", "", "", ""];
    console.log(address_components);

    for (const component of address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          street = `${component.long_name} ${street}`;
          break;
        }

        case "route": {
          street += component.short_name;
          break;
        }

        case "postal_code": {
          zip = `${component.long_name}${zip}`;
          break;
        }

        case "postal_code_suffix": {
          //zip = `${zip}-${component.long_name}`;
          break;
        }
        case "locality":
          locality = component.short_name;
          break;
        case "sublocality_level_1":
          sublocality = component.short_name;
          break;
        case "neighborhood":
          neighborhood = component.short_name;
          break;
        case "administrative_area_level_1": {
          state = component.short_name;
          break;
        }
        case "country":
          break;
      }
    }
    const city = locality || sublocality || neighborhood;

    return { street, city, state, zip };
  };

  return (
    <div className="autocomplete-container">
      <Box mb={2}>
        <TextField
          required
          name="street"
          variant="outlined"
          fullWidth
          id="street"
          label="Address"
          inputRef={inputRef}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onBlur={(e) => {
            if (place) {
              setQuery(getDescription(place));
            } else {
              setQuery("");
            }
          }}
        />
      </Box>
      <TextField
        id="unit"
        name="unit"
        variant="outlined"
        fullWidth
        label="Apt, suite or unit (optional)"
        value={props.value?.unit || ""}
        onChange={(e) => {
          onPlaceSelect({ ...props.value, unit: e.target.value });
        }}
      />
    </div>
  );
}
