import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Chip, Container, Grid, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../common/api";
import Product from "../../common/component/Product";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import ConfigContext from "../../common/context/ConfigContext";

export default function PhotoCollection(props) {
  const siteConfig = useContext(ConfigContext);

  const navigate = useNavigate();
  const { handle } = useParams();

  const [tag, setTag] = useState("");

  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  const [tags, setTags] = useState([]);
  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    bkstApi(`/site/${siteConfig.id}/photo/collection/${handle || "featured"}`)
      .then((res) => {
        setTitle(res.data.title);
        setDescription(res.data.description);

        document.title = res.data.title;
        setProducts(res.data.products);

        if (res.data.tags) {
          setTags(res.data.tags.sort());
        }

        setLoading(false);

        window.scrollTo(0, 100);
      })
      .catch((error) => {
        console.error("Error:", error);
        navigate("/");
      });
  }, [handle]);

  useEffect(() => {
    if (tag) {
      setLoading(true);

      bkstApi(`/site/${siteConfig.id}/photo/${tag}`)
        .then((res) => {
          setProducts(res.data.photos);
          setLoading(false);

          window.scrollTo(0, 100);
        })
        .catch((error) => {
          console.error("Error:", error);
          navigate("/");
        });
    }
  }, [tag]);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="md">
        <Box pt={5} align="center">
          <Typography variant="h2" component="h1" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {description}
          </Typography>
        </Box>
        <Box my={2}>
          <Box align="center">
            {tags.length > 0 && (
              <Box my={2} sx={{ display: "flex", gap: "3px", justifyContent: "center", flexWrap: "wrap" }}>
                {tags.map((t, i) => {
                  return <Chip key={i} size="small" label={t} clickable onClick={() => setTag(t)} />;
                })}
              </Box>
            )}
          </Box>
          <Box my={5}>
            <Grid container spacing={3}>
              {products.map((value, index) => {
                return (
                  <Grid item xs={12} sm={4} key={index} align="center">
                    <Box>
                      <Product img={value.img}>
                        <Button
                          size="large"
                          variant="contained"
                          component={RouterLink}
                          to="/get-quote/photo"
                          state={{ req: { pics: [value.img] } }}
                          fullWidth
                          disableElevation
                        >
                          Select & Customize
                        </Button>
                      </Product>
                      <Box>
                        <Typography color="textSecondary" variant="caption" display="block" gutterBottom>
                          You can customize color, decor, tiers...
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Container>
    );
  }
}
