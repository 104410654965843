import { Box, Chip, Paper, Typography } from "@mui/material";
import Option from "./Option";

export default function Question(props) {
  return (
    <Paper>
      <Box px={2} py={7}>
        <Box mb={4}>
          <Typography variant="h4" gutterBottom>{props.title}</Typography>
          <Typography variant="body1" color="textSecondary">{props.subtitle}</Typography>
        </Box>
        <Box my={2}>
          {props.options.map((o, idx) => {
            return <Option key={idx} title={o.title} icon={o.icon} onClick={() => props.handleClick(o.title)} />;
          })}
        </Box>
        {props.otherOptions && (
          <Box>
            {props.otherOptions.map((o, idx) => {
              return <Chip sx={{ margin: "4px" }} key={idx} size="medium" color="primary" variant="outlined" label={o} clickable onClick={() => props.handleClick(o)} />;
            })}
          </Box>
        )}
      </Box>
    </Paper>
  );
}
