import React, { useState, useRef, useEffect } from "react";
import { Box, Link, Button } from "@mui/material";
import { isTruncated } from "../util";

export default function Preview(props) {
  const ref = useRef(null);

  const [truncate, setTruncate] = useState(true);
  const [showMore, setShowMore] = useState(false);

  const lines = props.lines || "1";

  useEffect(() => {
    setShowMore(isTruncated(ref.current));
  }, [ref.current]);

  return (
    <Box>
      <Box
        ref={ref}
        style={{
          overflow: "hidden",
          display: "-webkit-box",
          "-webkit-line-clamp": truncate ? lines : "100",
          "line-clamp": truncate ? lines : "100",
          "-webkit-box-orient": "vertical"
        }}
      >
        {props.children}
      </Box>
      {truncate && showMore && (
        <Box mt={2}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            setTruncate(false);
          }}
        >
          Show More..
        </Button>
        </Box>
      )}
    </Box>
  );
}
