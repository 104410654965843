import React from "react";
import { Box, Checkbox, Chip, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import currency from "currency.js";
import { capitalize } from "../../common/util";

export default function ProductVariantSelection(props) {
  const { options, variant } = props;

  return (
    <Box>
      <Grid container spacing={3}>
        {options?.size && (
          <Grid item sm={12} xs={12}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="size-label">Size</InputLabel>
              <Select
                value={variant.size}
                labelId="size-label"
                label={`Size`}
                id="size"
                name="size"
                renderValue={(option) => {
                  return `${option.size} ${option.shape?.toLowerCase() || ""} ${option.servings ? `(${option.servings} servings)` : ""}` || "";
                }}
                onChange={(e) => {
                  props.onChange({ ...variant, size: e.target.value });
                }}
              >
                {options.size.map((option, idx) => {
                  let title = option.size;
                  if (option.shape) {
                    title += ` ${option.shape?.toLowerCase()}`;
                  }

                  if (option.servings) {
                    title += ` (${option.servings} servings)`;
                  }

                  let description = option.description || "";
                  if (option.frosting && option.shape) {
                    description += `${option.frosting} • ${option.shape}`;
                  }
                  return (
                    <MenuItem key={idx} value={option} style={{ whiteSpace: "normal" }}>
                      <Box>
                        <Typography variant="body1">{title}</Typography>
                        <Typography variant="caption" color="textSecondary">
                          {currency(option.pricing.amount).format()} • {description}
                        </Typography>
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        {options?.flavor && (
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="flavor-label">Flavor</InputLabel>
              <Select
                value={variant?.flavor?.base || ""}
                labelId="flavor-label"
                label={`Flavor`}
                id="flavor"
                name="flavor"
                renderValue={(option) => {
                  // check for surcharge
                  const tmp = options.flavor.filter((o) => o.name === option.name);
                  const surcharge = (tmp && tmp.length > 0 && tmp[0].pricing?.amount) || "";
                  return `${option.name} ${surcharge && ` • +${currency(surcharge).format()}`}`;
                }}
                onChange={(e) => {
                  let newValue = e.target.value;
                  // change format
                  let o = variant?.flavor || {};
                  o.base = { name: newValue.name };
                  if (newValue.pricing?.amount && +newValue.pricing?.amount > 0) {
                    o.base.pricing = { amount: newValue.pricing?.amount };
                  }
                  props.onChange({ ...variant, flavor: o });
                }}
                required
              >
                {options.flavor
                  .sort((o1, o2) => (o1.name > o2.name ? 1 : -1))
                  .map((option, idx) => (
                    <MenuItem key={idx} value={option} style={{ whiteSpace: "normal" }}>
                      <Box>
                        <Typography variant="body1">
                          {option.name} {+option.pricing?.amount > 0 && <Chip size="small" label={`premium`} />}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {+option.pricing?.amount > 0 ? `$${option.pricing?.amount} surcharge. ` : ""} {option.description}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {options?.filling && (
          <Grid item sm={12} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="filling-label">Filling</InputLabel>
              <Select
                value={variant?.flavor?.filling || ""}
                labelId="filling-label"
                label={`Filling`}
                id="filling"
                name="filling"
                renderValue={(option) => {
                  // check for surcharge
                  const tmp = options.filling.filter((o) => o.name === option.name);
                  const surcharge = (tmp && tmp.length > 0 && tmp[0].pricing?.amount) || "";
                  return `${option.name} ${surcharge && ` • +${currency(surcharge).format()}`}`;
                }}
                onChange={(e) => {
                  let newValue = e.target.value;
                  // change format
                  let o = variant?.flavor || {};
                  o.filling = { name: newValue.name };
                  if (newValue.pricing?.amount && +newValue.pricing?.amount > 0) {
                    o.filling.pricing = { amount: newValue.pricing?.amount };
                  }
                  props.onChange({ ...variant, flavor: o });
                }}
                required
              >
                {options.filling
                  .sort((o1, o2) => (o1.name > o2.name ? 1 : -1))
                  .map((option, idx) => (
                    <MenuItem key={idx} value={option} style={{ whiteSpace: "normal" }}>
                      <Box>
                        <Typography variant="body1">
                          {option.name} {+option.pricing?.amount > 0 && <Chip size="small" label={`premium`} />}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {+option.pricing?.amount > 0 ? `$${option.pricing?.amount} surcharge. ` : ""} {option.description}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {Object.entries(options).map(([key, value]) => {
          if (["size", "flavor", "filling", "add-on"].includes(key)) {
            return <Box />;
          } else {
            return (
              <Grid item sm={12} xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id={`${key}-label`}>{capitalize(key)}</InputLabel>
                  <Select
                    value={variant?.[key] || ""}
                    labelId={`${key}-label`}
                    label={capitalize(key)}
                    id={key}
                    renderValue={(option) => {
                      const surcharge = option?.pricing?.amount || "";
                      return `${option.name} ${surcharge && ` • +${currency(surcharge).format()}`}`;
                    }}
                    onChange={(e) => {
                      let o = { ...variant };
                      o[key] = e.target.value;
                      props.onChange(o);
                    }}
                    required
                  >
                    {value
                      .sort((o1, o2) => (o1.name > o2.name ? 1 : -1))
                      .map((option, idx) => (
                        <MenuItem key={idx} value={option} style={{ whiteSpace: "normal" }}>
                          <Box>
                            <Typography variant="body1">
                              {option.name} {+option.pricing?.amount > 0 && <Chip size="small" label={`premium`} />}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {+option.pricing?.amount > 0 ? `$${option.pricing?.amount} surcharge. ` : ""} {option.description}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            );
          }
        })}
        {options?.[`add-on`] && (
          <Grid item sm={12} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="add-on-label">Add-ons</InputLabel>
              <Select
                value={variant?.[`add-on`] || []}
                labelId="add-on-label"
                label={`Add-Ons`}
                id="add-on"
                name="add-on"
                multiple
                renderValue={(selected) => {
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value, idx) => (
                        <Chip key={idx} label={value.name} />
                      ))}
                    </Box>
                  );
                }}
                onChange={(e) => {
                  let o = { ...variant };
                  o[`add-on`] = e.target.value;
                  props.onChange(o);
                }}
              >
                {options?.[`add-on`]
                  .sort((o1, o2) => (o1.name > o2.name ? 1 : -1))
                  .map((option, idx) => {
                    let isSelected = false;
                    if (variant?.[`add-on`]?.filter((o) => o.name === option.name)?.length > 0) {
                      isSelected = true;
                    }
                    return (
                      <MenuItem key={idx} value={option} style={{ whiteSpace: "normal" }}>
                        <Checkbox checked={isSelected} />
                        <Box>
                          <Typography variant="body1">
                            {option.name} {+option.pricing?.amount > 0 && ` • +${currency(option.pricing?.amount).format()}`}{" "}
                            {+option.pricing?.factor > 0 && ` • +${+option.pricing?.factor * 100}%`}
                          </Typography>
                          {option.description && (
                            <Typography variant="caption" color="textSecondary">
                              {option.description}
                            </Typography>
                          )}
                        </Box>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
