import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Backdrop, Box, CircularProgress, Container, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../common/api";
import Verify from "./Verify";
import Design from "../component/Design";
import Customize from "../component/Customize";
import ContactDetails from "../component/ContactDetails";
import Event from "../component/Event";
import ConfigContext from "../../common/context/ConfigContext";
import Category from "../component/Category";
import AskServings from "../component/AskServings";

export default function RequestForQuote(props) {
  const siteConfig = useContext(ConfigContext);

  let location = useLocation();
  const navigate = useNavigate();

  const [step, setStep] = useState("");
  const [progress, setProgress] = useState(0);

  const [req, setReq] = useState({});
  const [reqId, setReqId] = useState();

  const stepRef = useRef(null);

  useEffect(() => {
    if (location.pathname === `/rfq`) {
      setStep("category");
      setProgress(10);
      window.scrollTo(0, 0);
    } else if (location.pathname === `/rfq/servings` && req.items) {
      setStep("servings");
      setProgress(20);
      stepRef.current.scrollIntoView();
    } else if (location.pathname === `/rfq/design` && req.servings) {
      setStep("design");
      setProgress(40);
      stepRef.current.scrollIntoView();
    } else if (location.pathname === `/rfq/customize` && req.servings && req.pics) {
      setStep("customize");
      setProgress(60);
      stepRef.current.scrollIntoView();
    } else if (location.pathname === `/rfq/event` && req.servings && req.pics) {
      setStep("event");
      setProgress(80);
      stepRef.current.scrollIntoView();
    } else if (location.pathname === `/rfq/contact` && req.pics && req.servings && req.location) {
      setStep("contact");
      setProgress(95);
      stepRef.current.scrollIntoView();
    } else {
      navigate("/rfq");
    }
  }, [location.pathname]);

  const setData = (data) => {
    Object.assign(req, data);
    setReq(req);

    console.log("SET", req);
  };

  const handleCategory = (data) => {
    setData(data);
    navigate("/rfq/servings");
  };

  const handleServings = (data) => {
    setData(data);

    if (req.items["Cake"]) {
      navigate("/rfq/design");
    } else {
      navigate("/rfq/design#upload");
    }
  };

  const handleDesign = (data) => {
    setData(data);
    navigate("/rfq/customize");
  };

  const handleEvent = (data) => {
    setData(data);
    navigate("/rfq/contact");
  };

  const handleCustomize = (data) => {
    setData(data);
    navigate("/rfq/event");
  };

  const submit = (data) => {
    setStep("submit");
    let payload = req;
    Object.assign(payload, data);

    // set bakerId
    payload.bakerId = siteConfig && siteConfig.id;

    // check utm params
    const utmParams = sessionStorage.getItem("utm");
    if (utmParams) {
      payload.utm = JSON.parse(utmParams);
    }

    bkstApi.post("/requests", payload).then((res) => {
      const reqId = res.data.id;

      setReqId(reqId);
      navigate(`/verify/${payload.phone}/${reqId}`);
    });
  };

  let currentStep = <Box />;
  if (step === "category") {
    currentStep = <Category onSubmit={handleCategory} setProgress={setProgress} />;
  } else if (step === "servings") {
    currentStep = <AskServings data={req} onSubmit={handleServings} />;
  } else if (step === "design") {
    currentStep = <Design onSubmit={handleDesign} setProgress={setProgress} />;
  } else if (step === "customize") {
    currentStep = <Customize data={req} onSubmit={handleCustomize} />;
  } else if (step === "event") {
    currentStep = <Event location={req.location} onSubmit={handleEvent} />;
  } else if (step === "contact") {
    currentStep = <ContactDetails location={req.location} onSubmit={submit} />;
  } else if (step === "submit") {
    currentStep = (
      <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else if (step === "verify") {
    currentStep = <Verify reqId={reqId} phone={req.phone} />;
  }

  return (
    <Container maxWidth="sm">
      <Box mt={6} pb={30} ref={stepRef}>
        <Typography variant="overline" color="textSecondary">
          Get a Quote
        </Typography>
        <LinearProgress color="primary" variant="determinate" value={progress} />
        {currentStep}
      </Box>
    </Container>
  );
}
