import { useState } from "react";
import { Box, Button, FormControl, Paper, TextField, Typography } from "@mui/material";
import Images from "../../common/component/image/Images";
import { generateTitle } from "../../common/util";

export default function Customize(props) {
  const [title, setTitle] = useState(generateTitle(props.data.items, props.data.keyword));
  const [details, setDetails] = useState(`I want the design, color & details to be as similar to the inspiration photos as possible. `);

  const onSubmit = (e) => {
    e.preventDefault();
    props.onSubmit({ title, details });
  };

  return (
    <Paper>
      <Box px={2} py={6}>
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            What would you like to customize?
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Pricing varies by design complexity, so be sure to include everything for an accurate quote
          </Typography>
        </Box>
        <Box my={4} align="center">
          <Images pics={props.data.pics} />
        </Box>
        <form onSubmit={onSubmit}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              value={title}
              variant="outlined"
              fullWidth
              id="title"
              label="Title"
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              required
              helperText="e.g. Lego Cake & Treats"
            />
          </FormControl>
          <Box my={2}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                value={details}
                variant="outlined"
                fullWidth
                id="details"
                label="Customization"
                name="details"
                multiline
                rows={Math.floor(details.length / 60) + 2}
                onChange={(e) => setDetails(e.target.value)}
                required
                helperText="Request customization to inspiration design. Change colors, tiers, design elements (e.g toppers, decals, figurines, flowers, etc.)"
              />
            </FormControl>
          </Box>
          <Button size="large" color="primary" variant="contained" type="submit" fullWidth>
            Continue
          </Button>
        </form>
      </Box>
    </Paper>
  );
}
