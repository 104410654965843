import { DialogActions } from "@mui/material";
import React from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

export default function BlockingError(props) {
  return (
    <Dialog open={true} fullWidth={true} maxWidth="sm">
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" fullWidth onClick={props.onClick}>
          {props.cta}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
