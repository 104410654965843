import React from "react";
import { Box, Chip, Grid, Paper, Typography } from "@mui/material";

export default function OrderInvoices(props) {
  const getBgColor = (status) => {
    if (status === "open") {
      return "#e67e22";
    } else if (status === "paid" || status === "refund") {
      return "#1abc9c";
    } else {
      return "#AAA";
    }
  };

  return (
    <Box my={6}>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Typography variant="overline" color="textSecondary">
            INVOICES
          </Typography>
        </Grid>
      </Grid>
      <Paper elevation={0}>
        <Box px={2} py={2}>
          {Object.entries(props.data)
            .sort()
            .map(([key, value]) => {
              return (
                <Box key={key} my={1}>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography>${value.amount.addOn}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="body2" color="textSecondary">
                        {value.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} align="right">
                      <Chip size="small" label={value.status} style={{ color: "#fff", backgroundColor: getBgColor(value.status) }} />
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
        </Box>
      </Paper>
    </Box>
  );
}
