import React, { useEffect, useState } from "react";
import { Autocomplete, IconButton, InputAdornment, TextField } from "@mui/material";
import bkstApi from "../../common/api";
import SearchIcon from "@mui/icons-material/Search";

export default function TagSearchBox(props) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [keyword, setKeyword] = useState(props.keyword || "");
  const [data, setData] = useState([]);

  const onChange = (e, v) => {
    if (v) {
      props.onSubmit(v);
    }
  };

  useEffect(() => {
    bkstApi("/tags/product").then((res) => {
      setData(res.data.tags);
    });
  }, []);

  return (
    <Autocomplete
      value={keyword}
      openOnFocus={false}
      onChange={onChange}
      options={data}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Search by Theme"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={() => props.onSubmit(inputValue)}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              paddingRight: 0
            },
            backgroundColor: "#FFFFFF"
          }}
        />
      )}
      freeSolo
      open={open}
      onOpen={() => {
        // only open when in focus and inputValue is not empty
        if (inputValue) {
          setOpen(true);
        }
      }}
      onClose={() => setOpen(false)}
      inputValue={inputValue}
      disableClearable={true}
      onInputChange={(e, value, reason) => {
        setInputValue(value);

        // only open when inputValue is not empty after the user typed something
        if (!value) {
          setOpen(false);
        }
      }}
    />
  );
}
