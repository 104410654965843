import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Container, FormControl, LinearProgress, Paper, Rating, TextField, Typography } from "@mui/material";
import bkstApi from "../../common/api";
import StaticPage from "../../common/screen/StaticPage";
import ReviewRatingQuestion from "../component/ReviewRatingQuestion";
import ConfigContext from "../../common/context/ConfigContext";
import GoogleIcon from "@mui/icons-material/Google";
import Images from "../../common/component/image/Images";
import { generateSizeSummary, getCustomerFirstNameLastInitial, getCustomerName } from "../../common/util";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import moment from "moment";
import LineItemV2 from "../../common/component/LineItem";

export default function OrderReview(props) {
  const ref = useRef(null);
  const detailRef = useRef(null);

  const siteConfig = useContext(ConfigContext);

  const { orderId } = useParams();
  const [order, setOrder] = useState("");
  const [placeId, setPlaceId] = useState("");

  const [rating, setRating] = useState(0);
  const [reviewId, setReviewId] = useState("");

  const [decoration, setDecoration] = useState(0);
  const [taste, setTaste] = useState(0);
  const [communication, setCommunication] = useState(0);
  const [fulfillment, setFulfillment] = useState(0);
  const [text, setText] = useState("");

  const [status, setStatus] = useState("loading");

  useEffect(() => {
    bkstApi(`/site/${siteConfig.id}/order/${orderId}/review`).then((res) => {
      setReviewId(res.data.reviewId);
      setPlaceId(res.data.placeId);
      setOrder(res.data.order);
      setStatus("entry");

      setTimeout(() => {
        ref.current.scrollIntoView();
      }, 500);
    });
  }, [orderId]);

  useEffect(() => {
    if (rating > 0) {
      bkstApi.post(`/site/${siteConfig.id}/order/${orderId}/review`, { rating }).then((res) => {
        setReviewId(res.data.reviewId);
      });
      detailRef.current.scrollIntoView();
    }
  }, [rating]);

  const onSubmit = (e) => {
    e.preventDefault();

    const payload = {
      decoration,
      taste,
      communication,
      fulfillment,
      text
    };
    bkstApi.put(`/site/${siteConfig.id}/order/${orderId}/review/${reviewId}`, payload).then((res) => {
      setStatus("done");
      ref.current.scrollIntoView();
    });
  };

  const onExternalClick = (destination) => {
    bkstApi.put(`/site/${siteConfig.id}/order/${orderId}/review/${reviewId}`, { rating, destination }).then((res) => {
      setStatus("done");
      ref.current.scrollIntoView();
    });
  };

  if (status === "loading") {
    return <LinearProgress />;
  } else if (status === "done") {
    return <StaticPage title="Thank you!" desc="We appreciate you taking the time to share your experience" icon={CheckCircleIcon}></StaticPage>;
  } else if (moment().isBefore(moment(order.fulfillment.date), "day") || moment().diff(moment(order.fulfillment.date), "days") >= 7) {
    return (
      <StaticPage title="Not Available" desc="We're sorry. This page is not available at the moment." icon={ErrorOutlineOutlinedIcon}></StaticPage>
    );
  } else {
    const orderSummary = (
      <Box p={1} my={2} sx={{ border: "1px solid #DEDEDE" }}>
        {order.items.map((item, idx) => (
          <Box key={idx} mb={1}>
            <LineItemV2 item={item} view="min" />
          </Box>
        ))}
      </Box>
    );

    const externalReview = (
      <Paper elevation={0}>
        <Box px={2} py={4} textAlign={"center"}>
          <Box mb={2}>
            <FavoriteIcon sx={{ fontSize: "3rem", color: "#c0392b" }} />
            <Typography variant="h5" gutterBottom>
              We're delighted to hear that!
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Please help spread the word by posting a review on Google. We greatly appreciate it.
            </Typography>
          </Box>
          <Button
            startIcon={<GoogleIcon />}
            variant="contained"
            color="primary"
            fullWidth
            component={"a"}
            target="_blank"
            href={`https://search.google.com/local/writereview?placeid=${placeId}`}
            onClick={() => onExternalClick("google")}
          >
            Post Review on Google
          </Button>
        </Box>
      </Paper>
    );

    const internalReview = (
      <form onSubmit={onSubmit}>
        <ReviewRatingQuestion heading="Appearance" subtitle="Were the items visually appealing?" onChange={(e) => setDecoration(e.target.value)} />
        <ReviewRatingQuestion heading="Taste" subtitle="Did you like the flavor?" onChange={(e) => setTaste(e.target.value)} />
        <ReviewRatingQuestion
          heading="Communication"
          subtitle="Were your queries answered in a timely manner?"
          onChange={(e) => setCommunication(e.target.value)}
        />
        <ReviewRatingQuestion
          heading="Pickup/Delivery"
          subtitle="Was the pickup/delivery easy & on-time?"
          onChange={(e) => setFulfillment(e.target.value)}
        />
        <Paper elevation={0} style={{ marginBottom: "1rem" }}>
          <Box p={2}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                value={text}
                variant="outlined"
                fullWidth
                id="text"
                label="What you loved & what could be better?"
                name="text"
                multiline
                required
                rows={4}
                onChange={(e) => setText(e.target.value)}
                inputProps={{
                  "data-hj-allow": ""
                }}
              />
            </FormControl>
          </Box>
        </Paper>
        <Box my={2} textAlign="right">
          <Button type="submit" variant="contained" color="primary" fullWidth>
            SUBMIT
          </Button>
        </Box>
      </form>
    );

    let destination = "internal";
    if (placeId && +rating >= 4) {
      destination = "external";
    }

    return (
      <Container maxWidth="sm" ref={ref}>
        <Box py={2} my={4}>
          <Box>
            <Paper elevation={0}>
              <Box px={2} py={4} textAlign={"center"}>
                <Typography variant="h4" gutterBottom>
                  {order.customer.firstName || order.customer.name}, how did we do?
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  We hope you enjoyed your order. On a scale of 1 to 5, how would you rate your experience?
                </Typography>
                <Box mt={2}>
                  <Rating name={rating} value={rating} size="large" onChange={(e) => setRating(e.target.value)} />
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box my={6} py={4} ref={detailRef}>
            {+rating > 0 && (
              <Box>
                {destination === "internal" && (
                  <Box>
                    {+rating < 4 && (
                      <Box mb={2} textAlign={"center"}>
                        <HeartBrokenIcon sx={{ fontSize: "3rem", color: "#c0392b" }} />
                        <Typography variant="h5" gutterBottom>
                          Sorry to hear that!
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Please help us understand what went wrong and we'll do our best to fix it
                        </Typography>
                      </Box>
                    )}
                    {internalReview}
                  </Box>
                )}
                {destination === "external" && <Box>{externalReview}</Box>}
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    );
  }
}
