import React, { useEffect, useState, useMemo } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import RequestForQuote from "./rfq/screen/RequestForQuote";
import RFQ from "./rfq2/screen/RequestForQuote";
import { getQueryParam, isAssistedCheckout } from "./common/util";
import ConfigContext from "./common/context/ConfigContext";
import Verify from "./rfq/screen/Verify";
import Order from "./order/screen/Order";
import Header from "./common/component/Header";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import bkstApi from "./common/api";
import Footer from "./common/component/Footer";
import { STORAGE_BUCKET } from "./common/constants";
import OrderInvoice from "./order/screen/OrderInvoice";
import OrderReview from "./order/screen/OrderReview";
import OrderBalancePay from "./order/screen/OrderBalancePay";
import PaymentStatusPage from "./order/screen/PaymentStatusPage";
import FaqPage from "./common/screen/FaqPage";
import Home from "./home/screen/Home";
import ProductPage from "./product/screen/ProductPage";
import OnlineShop from "./product/screen/OnlineShop";
import AppStateContext from "./common/context/AppStateContext";
import ClassPage from "./classes/screen/ClassPage";
import RentalPage from "./rental/screen/RentalPage";
import PhotoCollection from "./home/screen/PhotoCollection";
import Unsubscribe from "./quote/screen/Unsubscribe";
import { APIProvider } from "@vis.gl/react-google-maps";
import CartV3 from "./quote/screen/CartV3";
import QuoteV4 from "./quote/screen/QuoteV4";
import Booking from "./quote/screen/Booking";
import Classes from "./classes/screen/Classes";
import WebFont from "webfontloader";

export default function App(props) {
  const [siteConfig, setSiteConfig] = useState();

  const staffAssist = isAssistedCheckout();

  useEffect(() => {
    bkstApi(`/get-config`).then((res) => {
      const config = res.data;
      setSiteConfig(config);

      {
        config?.theme?.typography?.fontFamily &&
          WebFont.load({
            google: {
              families: config?.theme?.typography?.fontFamily
            }
          });
      }
      document.title = config.biz;

      const favicon = document.getElementById("favicon");
      favicon.href = `${STORAGE_BUCKET}/img/b/${config.id}/logo400.webp`;
    });

    const utms = getQueryParam(window.location.search, "utm_source");
    if (utms) {
      const utmm = getQueryParam(window.location.search, "utm_medium");
      const utmc = getQueryParam(window.location.search, "utm_campaign");

      sessionStorage.setItem("utm", JSON.stringify({ utms, utmm, utmc }));
    } else {
      var referrer = document.referrer;
      if (referrer) {
        var a = document.createElement("a");
        a.href = referrer;

        sessionStorage.setItem("utm", JSON.stringify({ utms: a.hostname, utmm: "", utmc: "" }));
      }
    }

    // assisted checkout
    const staffUid = getQueryParam(window.location.search, "su");
    if (staffUid) {
      sessionStorage.setItem("su", staffUid);
    }
  }, []);

  const theme = useMemo(() => {
    const themeColor = siteConfig?.theme.palette.primary || "#DDD";
    const fontFamily = siteConfig?.theme?.typography?.fontFamily?.[0] || "Roboto";
    const headingFontFamily = siteConfig?.theme?.typography?.h1?.fontFamily || fontFamily;
    const primaryButtonTextColor = siteConfig?.theme?.components?.MuiButton?.styleOverrides?.containedPrimary?.color || "#FFF";

    return createTheme({
      palette: {
        primary: { main: themeColor },
        secondary: {
          main: "#818181"
        },
        background: {
          default: "#F7F7F7"
        },
        error: {
          main: "#d63031"
        }
      },
      typography: {
        fontFamily,
        h1: {
          fontSize: "2.4rem",
          fontWeight: "700",
          fontFamily: headingFontFamily
        },
        h2: {
          fontSize: "1.8rem",
          fontWeight: "600",
          fontFamily: headingFontFamily
        },
        h3: {
          fontSize: "1.5rem",
          fontWeight: "600",
          fontFamily: headingFontFamily
        },
        h4: {
          fontSize: "1.3rem",
          fontWeight: "600",
          fontFamily: headingFontFamily
        },
        h5: {
          fontSize: "1.1rem",
          fontWeight: "600",
          fontFamily: headingFontFamily
        },
        h6: {
          fontSize: "1rem",
          fontWeight: "600",
          fontFamily: headingFontFamily
        },
        subtitle1: {
          fontSize: "1.1rem"
        },
        overline: {
          lineHeight: "2"
        }
      },
      components: {
        MuiAlert: {
          styleOverrides: {
            standardInfo: {
              color: "#2980b9"
            },
            filledSuccess: {
              color: "#fff",
              backgroundColor: "#10ac84"
            },
            filledWarning: {
              backgroundColor: "#ff9f43"
            }
          }
        },
        MuiAlertTitle: {
          styleOverrides: {
            root: {
              fontWeight: "600"
            }
          }
        },
        MuiTooltip: {
          styleOverrides: {
            popper: {
              zIndex: 70
            }
          }
        },
        MuiTab: {
          styleOverrides: {
            root: {
              fontSize: ".75rem"
            }
          }
        },
        MuiTabScrollButton: {
          styleOverrides: {
            root: {
              "&.Mui-disabled": {
                opacity: 0.3
              }
            }
          }
        },
        MuiButton: {
          styleOverrides: {
            containedPrimary: {
              color: primaryButtonTextColor
            }
          }
        },
        MuiToggleButton: {
          styleOverrides: {
            root: {
              "&.Mui-selected": {
                backgroundColor: themeColor,
                color: "#FFFFFF"
              },
              "&.Mui-selected:hover": {
                backgroundColor: themeColor
              },
              "&.MuiToggleButton-primary": {
                border: `1px solid ${themeColor}`
              }
            }
          }
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              backgroundColor: "#f7f7f7",
              borderBottom: "1px solid #eee",
              marginBottom: "10px"
            }
          }
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: "10px",
              border: "1px solid #eee",
              marginTop: "15px"
            }
          }
        }
      }
    });
  }, [siteConfig]);

  if (siteConfig) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppStateContext>
          <APIProvider apiKey={"AIzaSyAgje9mu_SVt-PPhkxrPmq6hTi_yE8rHAQ"}>
            <ConfigContext.Provider value={siteConfig}>
              {!staffAssist && <Header />}
              <Box style={{ minHeight: "100vh" }}>
                <Routes>
                  <Route path="/rfq/*" element={<RFQ {...props} />} />
                  <Route path="/get-quote/*" element={<RequestForQuote {...props} />} />
                  <Route path="/verify/:phone/:reqId" element={<Verify {...props} />} />
                  <Route path="/shop" element={<OnlineShop />} />
                  <Route path="/gallery" element={<PhotoCollection />} />
                  <Route path="/photo/collection/:handle" element={<PhotoCollection {...props} />} />
                  <Route path="/product/:productId" element={<ProductPage />} />
                  <Route path="/classes" element={<Classes />} />
                  <Route path="/class/:productId" element={<ClassPage />} />
                  <Route path="/rental/:productId" element={<RentalPage />} />
                  <Route path="/quote/:reqId" element={<QuoteV4 />} />
                  <Route path="/quotev4/:reqId" element={<QuoteV4 />} />
                  <Route path="/requests/:reqId" element={<QuoteV4 />} />
                  <Route path="/cart/:cartId" element={<CartV3 key={Date.now()} />} />
                  <Route path="/booking/:cartId" element={<Booking key={Date.now()} />} />
                  <Route path="/order/:orderId/in/:invoiceId" element={<OrderInvoice {...props} />} />
                  <Route path="/order/:orderId/balance-payment" element={<OrderBalancePay />} />
                  <Route path="/order/:orderId/review" element={<OrderReview />} />
                  <Route path="/order/:orderId" element={<Order />} />
                  <Route path="/payment-status" element={<PaymentStatusPage />} />
                  <Route path="/faq" element={<FaqPage />} />
                  <Route path="/unsubscribe/quote/:quoteId" element={<Unsubscribe />} />
                  {/*<Route path="/privacy-policy" element={<DynamicPage {...props} handle="privacy-policy" />} />
              <Route path="/terms-of-service" element={<DynamicPage {...props} handle="terms-of-service" />} />*/}
                  <Route path="*" element={<Home {...props} />} />
                </Routes>
              </Box>
              {!staffAssist && <Footer />}
            </ConfigContext.Provider>
          </APIProvider>
        </AppStateContext>
      </ThemeProvider>
    );
  } else {
    return (
      <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}
