import React, { useContext } from "react";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar
} from "@mui/material";
import { STORAGE_BUCKET } from "../../common/constants";
import ConfigContext from "../../common/context/ConfigContext";
import { Link as RouterLink } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { AppStateContext } from "../context/AppStateContext";

let defaultMenu = [
  { title: "Get Quote", link: "/get-quote" },
  { title: "Order Now", link: "/shop" },
  { title: "Gallery", link: "/#gallery" },
  { title: "Reviews", link: "/#reviews" },
  { title: "Flavors", link: "/#flavors" },
  { title: "About", link: "/#about" }
];
const drawerWidth = 240;

export default function Header(props) {
  const siteConfig = useContext(ConfigContext);
  const { cart } = useContext(AppStateContext);

  const menuOptions = siteConfig.header?.menu || defaultMenu;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  let logoImage = (
    <Avatar alt={siteConfig.bizName} src={`${STORAGE_BUCKET}/img/b/${siteConfig.id}/logo400.webp`} style={{ width: "75px", height: "75px" }} />
  );
  if (siteConfig.header?.logo?.src) {
    // custom header logo
    logoImage = <img src={siteConfig.header?.logo?.src} style={{ height: "75px" }} />;
  }

  let logo = (
    <Link component={RouterLink} underline="none" to={"/"} style={{ color: "inherit", cursor: "pointer" }}>
      {logoImage}
    </Link>
  );
  if (siteConfig.header?.logo?.target) {
    logo = (
      <Link component={"a"} underline="none" href={siteConfig.header?.logo?.target} style={{ color: "inherit", cursor: "pointer" }}>
        {logoImage}
      </Link>
    );
  }

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <Box py={1} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Avatar src={`${STORAGE_BUCKET}/img/b/${siteConfig.id}/logo400.webp`} style={{ width: "50px", height: "50px" }} />
        <IconButton size="large">
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <List>
        {menuOptions.map((o, idx) =>
          o.type === "external" ? (
            <ListItem key={idx} component={"a"} href={o.link} disablePadding sx={{ color: "#555" }}>
              <ListItemButton>
                <ListItemText primary={o.title} />
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem key={idx} component={RouterLink} to={o.link} disablePadding sx={{ color: "#555" }}>
              <ListItemButton>
                <ListItemText primary={o.title} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        position="static"
        style={{
          color: "#555",
          backgroundColor: "#FEFEFE",
          paddingTop: "5px",
          paddingBottom: "5px",
          boxShadow: "none"
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ display: "flex" }}>
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
              <MenuIcon />
            </IconButton>
            <Box sx={{ paddingLeft: "25px", flexGrow: 1, gap: "10px", display: { xs: "none", sm: "flex", justifyContent: "center" } }}>
              {logo}
              {menuOptions.map((o, idx) =>
                o.type === "external" ? (
                  <Button size="small" key={idx} component={"a"} href={o.link} sx={{ color: "#555", fontSize: ".75rem" }}>
                    {o.title}
                  </Button>
                ) : (
                  <Button size="small" key={idx} component={RouterLink} to={o.link} sx={{ color: "#555", fontSize: ".75rem" }}>
                    {o.title}
                  </Button>
                )
              )}
            </Box>
            <Box sx={{ display: { flexGrow: 1, xs: "flex", sm: "none" }, mr: 1, justifyContent: "center" }}>{logo}</Box>
            <Box sx={{ flexGrow: 0 }}>
              {cart && cart.quantity > 0 && (
                <IconButton component={RouterLink} to={`/cart/${cart.id}`}>
                  <Badge badgeContent={cart.quantity} color="primary">
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth }
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
