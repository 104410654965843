import React, { useState } from "react";
import { Box, Slide } from "@mui/material";
import MultiSelectQuestion from "../../common/component/MultiSelectQuestion";

const CATEGORIES = [
  { title: "Cake", icon: "outline/birthday-cake.png" },
  { title: "Cupcakes", icon: "outline/cupcake.png" },
  { title: "Cookies", icon: "outline/cookies.png" },
  { title: "Cake Pops", icon: "outline/lollipop.png" },
  { title: "Macarons", icon: "outline/macarons.png" },
  { title: "Shooters", icon: "outline/mousse.png" }
];

export default function Categories(props) {
  const [items, setItems] = useState({});

  const onSubmit = () => {
    props.onSubmit({
      items
    });
  };

  const onChange = (item, checked) => {
    let tmp = { ...items };

    if (checked) {
      tmp[item] = { servings: 0 };
    } else {
      delete tmp[item];
    }
    setItems(tmp);
  };

  return (
    <Box textAlign="center">
      <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
        <Box>
          <MultiSelectQuestion
            title="What are you looking for?"
            subtitle="Select all that apply"
            options={CATEGORIES}
            handleClick={onChange}
            onSubmit={onSubmit}
            multiple
          />
        </Box>
      </Slide>
    </Box>
  );
}
