import React, { useState, useRef, useEffect } from "react";
import { Box, Container, LinearProgress, Paper, TextField, Typography } from "@mui/material";
import bkstApi from "../../common/api";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { formatPhoneNumber } from "../../common/util";

export default function Verify(props) {
  const ref = useRef(null);

  const navigate = useNavigate();
  const { reqId, phone } = useParams();

  const [error, setError] = useState(false);

  useEffect(() => {
    ref.current.scrollIntoView();
  }, []);

  const verifyCode = (code) => {
    if (code.length < 4) {
      setError(false);
    } else if (code.length === 4) {
      bkstApi.put(`/request/${reqId}/verify-code`, { code }).then((res) => {
        if (res.data.status === "verified") {
          navigate(`/quote/${reqId}`);
        } else {
          setError(true);
        }
      });
    } else if (code.length > 4) {
      setError(true);
    }
  };

  return (
    <Container maxWidth="md" ref={ref}>
      <Box my={7}>
      <LinearProgress color="primary" variant="determinate" value={98} />
      <Paper>
        <Box px={3} py={10} mb={10} textAlign="center">
          <PhoneAndroidOutlinedIcon style={{ fontSize: "5rem" }} />
          <Typography variant="h5" gutterBottom>
            Enter the verification code
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">{`We sent a text message with a verification code to ${ formatPhoneNumber(phone)}`}</Typography>
          <Box mt={10}>
            <TextField
              inputProps={{ style: { width: "16rem", textAlign: "center", fontSize: "4rem", letterSpacing: "1rem" } }}
              error={error}
              variant="filled"
              type="number"
              id="code"
              name="code"
              required
              autoFocus
              onChange={(e) => verifyCode(e.target.value)}
              helperText={error ? "Incorrect code" : ""}
            />
          </Box>
        </Box>
      </Paper>
      </Box>
    </Container>
  );
}
