import React, { useContext } from "react";
import { Avatar, Box, Grid, Paper, Rating, Typography } from "@mui/material";
import moment from "moment";
import ReviewRatingBar from "./ReviewRatingBar";
import { STORAGE_BUCKET } from "../../common/constants";
import ConfigContext from "../../common/context/ConfigContext";

export default function Review(props) {
  const siteConfig = useContext(ConfigContext);

  let source = props.data.source;
  if (props.data.source === "goog") {
    source = "google";
  } else if (props.data.source === "fb") {
    source = "facebook";
  }

  return (
    <Paper elevation={0}>
      <Box px={2} pt={2} pb={3}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Avatar style={{ height: "32px", width: "32px" }}>{props.data.name.charAt(0).toUpperCase()}</Avatar>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" display="inline">
              {props.data.name}
            </Typography>
            {props.data.source !== "bkst" && (
              <Typography variant="caption" color="textSecondary">
                &nbsp;&nbsp;{`via ${source}`}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Box pt={1}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Rating precision={0.25} name="rating" value={Number(props.data.rating)} readOnly size="small" />
            </Grid>
            <Grid item>
              <Typography variant="overline" style={{ fontWeight: "600" }}>
                {props.data.rating}
              </Typography>
            </Grid>
            {/* <Grid item>
              { props.data.ts?._seconds && <Typography variant="overline" color="textSecondary">
                {moment(props.data.ts._seconds * 1000).format("MMM DD")}
              </Typography> }
            </Grid> */ }
          </Grid>
          {/* <Box pb={3}>
            <Grid container spacing={1}>
              <Grid item sm={8} xs={12}>
                <Grid container spacing={1}>
                  {Boolean(props.data.decoration) && (
                    <Grid item sm={6} xs={6}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          Decor
                        </Typography>
                        <ReviewRatingBar value={props.data.decoration} />
                      </Box>
                    </Grid>
                  )}
                  {Boolean(props.data.taste) && (
                    <Grid item sm={6} xs={6}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          Taste
                        </Typography>
                        <ReviewRatingBar value={props.data.taste} />
                      </Box>
                    </Grid>
                  )}
                  {Boolean(props.data.communication) && (
                    <Grid item sm={6} xs={6}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          Communication
                        </Typography>
                        <ReviewRatingBar value={props.data.communication} />
                      </Box>
                    </Grid>
                  )}
                  {Boolean(props.data.fulfillment) && (
                    <Grid item sm={6} xs={6}>
                      <Box>
                        <Typography variant="caption" color="textSecondary">
                          Fulfillment
                        </Typography>
                        <ReviewRatingBar value={props.data.fulfillment} />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box> */}
          <Box mt={2} textAlign={"left"}>
          <Typography variant="body1" color="textSecondary">{props.data.text}</Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
