import React, { useState } from "react";
import { Backdrop, Box, Button, CircularProgress, Typography } from "@mui/material";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import currency from "currency.js";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      backgroundColor: "#fff",
      fontSmoothing: "antialiased",
      fontSize: "18px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

export default function StripeCardElement(props) {
  const { name, phone, amount, chargePaymentMethod, validate } = props;

  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setError("");
    const isValid = await validate();
    if (isValid) {
      setProcessing(true);

      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name,
          phone
        }
      });

      setProcessing(false);

      if (result.error) {
        setError(result.error.message);
      } else {
        // Otherwise send paymentMethod.id to your server (see Step 4)
        chargePaymentMethod(result.paymentMethod.id, amount);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <Box align="center" my={1}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}
      <CardElement options={CARD_ELEMENT_OPTIONS} id="card-element" />
      <Button size="large" type="submit" color="primary" variant="contained" fullWidth>
        {`Charge ${currency(amount).format()}`}
      </Button>
      {processing && (
        <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </form>
  );
}
