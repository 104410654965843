import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, Paper, TextField, Typography } from "@mui/material";
import PhoneField from "../../common/component/PhoneField";
import ConfigContext from "../../common/context/ConfigContext";
import bkstApi from "../../common/api";

export default function ContactDetails(props) {
  const { data } = props;

  const siteConfig = useContext(ConfigContext);
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [consent, setConsent] = useState(false);

  const [errors, setErrors] = useState([]);

  const validate = () => {
    let arr = [];
    if (!phone) {
      arr.push("Phone is missing");
    } else if (phone.length < 10) {
      arr.push("Invalid Phone Number");
    }

    if (email.length < 5 || !email.includes("@") || !email.includes(".")) {
      arr.push("Invalid Email");
    }

    return arr;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const arr = validate();
    if (arr.length > 0) {
      setErrors(arr);
    } else {
      let payload = { ...data, name: `${firstName} ${lastName}`, firstName, lastName, email, phone };

      // check utm params
      const utmParams = sessionStorage.getItem("utm");
      if (utmParams) {
        payload.utm = JSON.parse(utmParams);
      }

      bkstApi
        .post(`/site/${siteConfig.id}/quote`, payload)
        .then((res) => {
          navigate(`/verify/${payload.phone}/${res.data.id}`);
        })
        .catch((error) => {
          setErrors([error.response?.data?.msg || "Please check the data & try again"]);
        });
    }
  };

  return (
    <Paper>
      <Box px={2} py={7} textAlign="center">
        <Box>
          <Grid container spacing={3}>
            <Grid item sm={3} xs={12}></Grid>
            <Grid item sm={6} xs={12}>
              <Box mb={4}>
                <Typography variant="h4" gutterBottom>
                  Final Step!
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  We will review your details & send you a quote within a few hours
                </Typography>
              </Box>
              <form onSubmit={onSubmit}>
                <Grid container spacing={3} align="left">
                  <Grid item sm={6} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={firstName}
                        variant="outlined"
                        fullWidth
                        id="firstName"
                        label="First Name"
                        name="firstName"
                        onChange={(e) => setFirstName(e.target.value)}
                        inputProps={{
                          "data-hj-allow": ""
                        }}
                        required
                      />
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={lastName}
                        variant="outlined"
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        onChange={(e) => setLastName(e.target.value)}
                        inputProps={{
                          "data-hj-allow": ""
                        }}
                        required
                      />
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={email}
                        variant="outlined"
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <PhoneField name="Mobile" value={phone} onChange={(phone) => setPhone(phone)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      className="consent"
                      control={
                        <Checkbox required checked={consent} onChange={(e) => setConsent(e.target.checked)} name="consent" color="secondary" />
                      }
                      label={`I consent to receiving calls and SMS messages from ${siteConfig.biz}`}
                    />
                  </Grid>
                  {errors.length > 0 && (
                    <Grid item xs={12} align="right">
                      <Alert severity="error">{errors.join(". ")}</Alert>
                    </Grid>
                  )}
                  <Grid item xs={12} align="right">
                    <Button color="primary" variant="contained" type="submit" fullWidth>
                      Get Quotes
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
