import React from "react";
import { Box, Divider, Grid, Link, Paper, Typography } from "@mui/material";
import currency from "currency.js";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";

export default function OrderPayment(props) {
  const { order } = props;

  let dueDate = moment(order.fulfillment.date).subtract(7, "days");
  if (order.payment.dueDate) {
    dueDate = moment(order.payment.dueDate);
  }

  return (
    <Paper elevation={0}>
      <Box px={2} py={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              Subtotal
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography variant="overline" color="textSecondary">
              {currency(order.payment.subtotal).format()}
            </Typography>
          </Grid>
        </Grid>
        {order.payment.delivery > 0 && (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                Delivery
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="overline" color="textSecondary">
                {currency(order.payment.delivery).format()}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              Tip
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography variant="overline" color="textSecondary">
              {currency(order.payment.tip).format()}
            </Typography>
          </Grid>
        </Grid>
        {order.payment.discount > 0 && (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                Discount
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="overline">{currency(order.payment.discount).format()}</Typography>
            </Grid>
          </Grid>
        )}
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                Total
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="overline">{currency(order.payment.total).format()}</Typography>
            </Grid>
          </Grid>
        </Box>
        {order.payment.due > 0 && (
          <Box>
            <Box mt={1} mb={2}>
              <Divider />
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  Amount Paid
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography variant="overline" color="textSecondary">
                  ({currency(order.payment.paid).format()})
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  Balance Due {dueDate.format("MMM DD")}
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography display="inline" variant="overline">
                  <Link
                    color="primary"
                    underline="always"
                    component={RouterLink}
                    to={`/order/${order.id}/balance-payment`}
                    style={{ marginRight: "10px" }}
                  >
                    Pay Now
                  </Link>
                  {currency(order.payment.due).format()}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {order.payment.refund && (
          <Box>
            <Box my={2}>
              <Divider />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="overline" color="error">
                  Refund
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                <Typography variant="overline" color="error">
                  {currency(
                    order.payment.refund.reduce((acc, obj) => {
                      return +acc + +obj.amount;
                    }, 0)
                  ).format()}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Paper>
  );
}
