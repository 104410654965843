import React from "react";
import currency from "currency.js";
import { Box, Divider, Grid, Typography } from "@mui/material";

export default function OrderPaymentSummaryV2(props) {
  const { subtotal, delivery, tip, discount, total, due, date } = props;
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="overline" color="textSecondary">
            Subtotal
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <Typography variant="overline" color="textSecondary">
            {currency(subtotal).format()}
          </Typography>
        </Grid>
      </Grid>
      {delivery > 0 && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              Delivery
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Typography variant="overline" color="textSecondary">
              {currency(delivery).format()}
            </Typography>
          </Grid>
        </Grid>
      )}
      {tip > 0 && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              Tip
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Typography variant="overline" color="textSecondary">
              {currency(tip).format()}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Box>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={9}>
            <Typography variant="overline" color="textSecondary">
              TOTAL (USD)
            </Typography>
          </Grid>
          <Grid item xs={3} align="right">
            <Typography variant="overline" color="textSecondary">
              {currency(total).format()}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {due < total && (
        <Box>
          <Box my={1}>
            <Divider />
          </Box>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={9}>
              <Typography variant="body1">Payment due today</Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Typography variant="overline" style={{ fontSize: "1rem", fontWeight: "700" }}>
                {currency(due).format()}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={9}>
              <Typography variant="body2" color="textSecondary">
                Balance due on {date}
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Typography variant="overline" color="textSecondary">
                {currency(total - due).format()}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
