import React from "react";
import { Popover, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export default function InfoPopover(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const color = props.info ? "#0984e3" : "#AAA";

  return (
    <span>
      <InfoIcon
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ color, fontSize: "1rem", verticalAlign: "middle" }}
      />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none"
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{ sx: { backgroundColor: "#333", color: "#fff" } }}
      >
        <Typography variant="body2" sx={{ p: 1 }}>{props.text}</Typography>
      </Popover>
    </span>
  );
}
