import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  LinearProgress,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../common/api";
import ConfigContext from "../../common/context/ConfigContext";
import currency from "currency.js";
import { isAssistedCheckout } from "../../common/util";
import moment from "moment";
import LatePaymentPolicy from "../../order/component/LatePaymentPolicy";
import OrderCancelationPolicy from "../../order/component/OrderCancelationPolicy";
import StripePaymentElement from "../component/StripePaymentElement";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTheme } from "@mui/material/styles";
import OrderPaymentSummaryV2 from "../component/OrderPaymentSummaryV2";
import PhoneField from "../../common/component/PhoneField";
import { Link as RouterLink } from "react-router-dom";
import { AppStateContext } from "../../common/context/AppStateContext";
import StripeCardElement from "../../common/component/StripeCardElement";
import { useNavigate } from "react-router";
import LineItem from "../../common/component/LineItem";

const key = process.env.REACT_APP_STRIPE_KEY;
const promise = loadStripe(key);

export default function Booking(props) {
  const siteConfig = useContext(ConfigContext);
  const navigate = useNavigate();

  const { setCartState } = useContext(AppStateContext);

  const theme = useTheme();
  const ref = useRef(null);

  const { cartId } = useParams();

  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");

  const [cart, setCart] = useState("");
  const [payment, setPayment] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [paymentDueType, setPaymentDueType] = useState("full");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  const stripeOptions = {
    clientSecret,
    appearance: {
      labels: "floating",
      variables: {
        colorPrimary: theme.palette.primary.main,
        colorDanger: "#df1b41",
        borderRadius: "4px"
      }
    }
  };

  const staffAssist = isAssistedCheckout();

  useEffect(() => {
    window.scrollTo(0, 100);
    setProcessing(true);

    bkstApi(`/site/${siteConfig.id}/booking/${cartId}`).then((res) => {
      setClientSecret(res.data.cart.clientSecret);
      setPaymentIntentId(res.data.cart.paymentIntentId);

      setCart(res.data.cart);

      setPayment(res.data.cart.payment);

      setPaymentDueType(+res.data.cart.payment.due < +res.data.cart.payment.total ? "deposit" : "full");

      setLoading(false);
      setProcessing(false);

      const itemCount = res.data.cart.items.reduce((acc, o) => +acc + +o.quantity, 0);
      setCartState(cartId, itemCount);
    });
  }, [cartId]);

  const validate = async () => {
    // check required fields
    let errors = "";

    if (!cart.customer) {
      if (!name) {
        errors += "Name is missing. ";
      }
      if (!phone) {
        errors += "Phone is missing. ";
      } else if (phone.length < 10) {
        errors += "Invalid Phone Number. ";
      }

      if (!email) {
        errors += "Email is missing. ";
      } else if (email.length < 5 || !email.includes("@") || !email.includes(".")) {
        errors += "Invalid Email. ";
      }
    }

    if (errors) {
      setError(errors);
      return false;
    } else {
      setProcessing(true);

      const result = await bkstApi
        .post(`/site/${siteConfig.id}/cartV2/${cartId}/verify`, {
          paymentIntentId,
          shipping: cart.shipping,
          due: payment.due,
          paymentDueType,
          customer: { name, email, phone }
        })
        .then((res) => {
          setCart(res.data.cart);
          setPayment(res.data.cart.payment);

          setProcessing(false);

          return true;
        })
        .catch((error) => {
          if (error?.response?.data) {
            if (error.response.data.cart) {
              setCart(error.response.data.cart);
              setPayment(error.response.data.cart.payment);
            }
            setError(error.response.data.msg);
          } else {
            setError("Unexpected error. Please refresh this page and try again");
          }
          setProcessing(false);

          return false;
        });

      return result;
    }
  };

  const chargePaymentMethod = (paymentMethodId) => {
    const payload = {
      paymentIntentId,
      paymentMethodId
    };

    bkstApi
      .post(`/site/${siteConfig.id}/moto`, payload)
      .then((res) => {
        navigate(`/payment-status?cart_id=${cartId}&payment_intent_client_secret=${clientSecret}`);
      })
      .catch((error) => {
        if (error?.response?.data) {
          setError(error.response.data.msg);
        } else {
          setError("Payment Declined. Please close this window and try again.");
        }
        setProcessing(false);

        return false;
      });
  };

  if (loading) {
    return (
      <Box>
        <LinearProgress ref={ref} />{" "}
        {processing && (
          <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Box>
    );
  } else {
    if (cart.items && cart.items.length > 0) {
      return (
        <Container maxWidth="sm" sx={{ paddingTop: "3rem", paddingBottom: "10rem" }}>
          <Box mb={3} ref={ref}>
            <Typography variant="h2" gutterBottom>
              Checkout
            </Typography>
          </Box>
          <Dialog open={Boolean(error)} fullWidth={true} maxWidth="sm">
            <DialogTitle>Cart Error</DialogTitle>
            <DialogContent>
              <Typography>Please correct the errors below to proceed with your order. Your card has not been charged yet. </Typography>
              <Box mt={2} mb={4}>
                <Typography color="error">{error}</Typography>
              </Box>
              <Box>
                <Button variant="contained" color="primary" fullWidth onClick={() => setError("")}>
                  CLOSE
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
          <Typography variant="overline" color="textSecondary">
            ITEMS
          </Typography>
          <Paper elevation={0}>
            <Box px={2} py={2}>
              {cart.items.map((item, idx) => (
                <Box key={idx} mb={1}>
                  <LineItem item={item} view="min" />
                </Box>
              ))}
            </Box>
          </Paper>
          <Box my={4}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Typography variant="overline" color="textSecondary">
                  WHERE
                </Typography>
                <Paper elevation={0}>
                  <Box p={2}>
                    <Typography variant="body1" gutterBottom>
                      {cart.shipping.location.street}
                      {cart.shipping.location.unit && `, ${cart.shipping.location.unit}`}
                    </Typography>
                    <Typography variant="body1">
                      {cart.shipping.location.city}, {cart.shipping.location.state} {cart.shipping.location.zip}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography variant="overline" color="textSecondary">
                  WHEN
                </Typography>
                <Paper elevation={0}>
                  <Box p={2}>
                    <Typography variant="body1" gutterBottom>
                      {moment(cart.shipping.date, "YYYY-MM-DD").format("ddd, MMM D, YYYY")}
                    </Typography>
                    <Typography variant="body1">{cart.shipping.time}</Typography>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Box my={4}>
            <Typography variant="overline" color="textSecondary">
              CONTACT DETAILS
            </Typography>
            <Paper elevation={0}>
              <Box px={2} pt={3} pb={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={name}
                        variant="outlined"
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={email}
                        variant="outlined"
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                      <PhoneField name="Phone" value={phone} onChange={(phone) => setPhone(phone)} required />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
          <Box my={4}>
            <Typography variant="overline" color="textSecondary">
              PAYMENT SUMMARY
            </Typography>
            <Paper elevation={0}>
              <Box px={2} py={2}>
                <OrderPaymentSummaryV2
                  subtotal={payment.subtotal}
                  delivery={payment.delivery}
                  tip={payment.tip}
                  total={payment.total}
                  due={payment.due}
                />
              </Box>
            </Paper>
          </Box>
          <Box my={4}>
            <Typography variant="overline" color="textSecondary" gutterBottom>
              Pay with
            </Typography>
            <Paper elevation={0}>
              <Box px={2} py={3}>
                {staffAssist ? (
                  <Elements stripe={promise}>
                    <StripeCardElement
                      disabled={loading}
                      validate={validate}
                      chargePaymentMethod={chargePaymentMethod}
                      amount={currency(payment.due).format()}
                      name={name}
                      phone={phone}
                    />
                  </Elements>
                ) : (
                  <Elements stripe={promise} options={stripeOptions}>
                    <StripePaymentElement
                      amount={currency(payment.due).format()}
                      disabled={loading}
                      validate={validate}
                      returnUrl={`${window.location.origin}/payment-status?cart_id=${cartId}`}
                      showPolicy={true}
                    />
                  </Elements>
                )}
              </Box>
            </Paper>
          </Box>
          <Box mt={10}>
            <OrderCancelationPolicy date={cart.shipping.date} total={payment.total} tip={payment.tip} delivery={payment.delivery} ts={Date.now()} />
            <LatePaymentPolicy />
          </Box>
          {processing && (
            <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Container>
      );
    } else {
      return (
        <Box my={6} textAlign={"center"}>
          <Box mb={2}>
            <Typography variant="h2" gutterBottom>
              Your cart is empty!
            </Typography>
          </Box>
          <Button variant="contained" color="primary" size="large" component={RouterLink} to="/shop">
            Shop Now
          </Button>
        </Box>
      );
    }
  }
}
