import React from "react";
import { Box, Button, Chip, Paper, Typography } from "@mui/material";
import MultiSelectOption from "./MultiSelectOption";

export default function MultiSelectQuestion(props) {
  return (
    <Paper>
      <Box px={2} py={6}>
        <Box mb={4}>
          <Typography variant="h4" gutterBottom>
            {props.title}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {props.subtitle}
          </Typography>
        </Box>
        <Box my={4} sx={{ display: "flex", gap: "20px", flexWrap: "wrap", justifyContent: "center" }}>
          {props.options.map((o, idx) => {
            return <MultiSelectOption key={idx} title={o.title} icon={o.icon} handleClick={props.handleClick} multiple={props.multiple} />;
          })}
        </Box>
        {props.otherOptions && (
          <Box>
            <Typography variant="overline" color="textSecondary" display="block" gutterBottom>
              Other options
            </Typography>
            {props.otherOptions.map((o, idx) => {
              return (
                <Chip
                  sx={{ margin: "4px" }}
                  key={idx}
                  size="medium"
                  color="primary"
                  variant="outlined"
                  label={o}
                  clickable
                  onClick={() => props.handleClick(o, true)}
                />
              );
            })}
          </Box>
        )}
        {props.multiple && (
          <Box px={4}>
            <Button size="large" color="primary" variant="contained" onClick={props.onSubmit} fullWidth>
              Continue
            </Button>
          </Box>
        )}
      </Box>
    </Paper>
  );
}
