import React, { useEffect, useState } from "react";
import { Box, Button, Grow, Paper, Slide, Typography } from "@mui/material";
import Images from "../../common/component/image/Images";
import { useNavigate, useLocation } from "react-router-dom";
import SearchDesigns from "./SearchDesigns";
import Themes from "./Themes";
import MultiSelectQuestion from "../../common/component/MultiSelectQuestion";

const designOptions = [
  { title: "search", icon: "search.svg" },
  { title: "upload", icon: "upload.svg" }
];

const occasions = [
  { title: "birthday", icon: "birthday.svg" },
  { title: "baby shower", icon: "teddy.svg" },
  { title: "wedding", icon: "wedding.svg" },
  { title: "anniversary", icon: "anniversary.svg" }
];

const birthdayOptions = [
  { title: "baby", icon: "infant.svg" },
  { title: "small kid", icon: "toddler.svg" },
  { title: "teen", icon: "bigkid.svg" },
  { title: "adult", icon: "adult.svg" }
];

const tagsDb = {
  wedding: ["abstract", "bohemian", "floral", "geode", "ombre", "semi naked", "textured"],
  graduation: ["book", "doctor", "lawyer", "striped", "quilted", "dentist"],
  "baby-shower": ["angel", "balloon", "celestial", "cloud", "floral", "gender reveal", "stork"],
  anniversary: ["boho", "fashion", "floral", "heart", "liquor", "textured", "travel"],
  baptism: [],
  business: ["doctor", "lawyer", "police", "dj", "camera", "computer"]
};

export default function Design(props) {
  const navigate = useNavigate();
  let location = useLocation();

  const [step, setStep] = useState("");

  const [occasion, setOccasion] = useState("");
  const [lifestage, setLifestage] = useState("");
  const [tags, setTags] = useState();

  const [pics, setPics] = useState([]);

  useEffect(() => {
    if (!location.hash) {
      setStep("design");
    } else if (location.hash === "#occasion") {
      setStep("occasion");
    } else if (location.hash === "#lifestage") {
      setStep("lifestage");
      props.setProgress(20);
    } else if (location.hash.startsWith("#theme/")) {
      setStep("theme");
      props.setProgress(25);
    } else if (location.hash.startsWith("#search")) {
      setStep("search");
      props.setProgress(35);
    } else if (location.hash === "#upload") {
      setStep("upload");
    }
  }, [location.hash]);

  const onSubmit = (keyword, photos) => {
    setPics(photos);
    props.onSubmit({ occasion, lifestage, pics: photos, keyword });
  };

  const handleLifestage = (value) => {
    setLifestage(value);
    navigate(`#theme/${value.replace(/\s/g, "")}`);
  };

  const handleTheme = (o) => {
    setTags(o.tags);
    navigate(`#search/${o.keyword.replace(/\s/g, "-")}`);
  };

  const handleOccasion = (value) => {
    console.log("inide handleOccassion", value);
    setOccasion(value);
    if (value === "birthday") {
      navigate("#lifestage");
    } else if (value === "other") {
      navigate(`#theme/other`);
    } else if (value === "anniversary") {
      navigate(`#theme/anniversary`);
    } else {
      const token = value.replace(/\s/g, "-");
      setTags(tagsDb[token]);
      navigate(`#search/${token}`);
    }
  };

  const handleDesign = (value) => {
    if (value === "search") {
      navigate(`#occasion`);
    } else {
      navigate(`#upload`);
    }
  };

  return (
    <Box textAlign="center">
      {step === "occasion" && (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
          <Box>
            <MultiSelectQuestion
              title="What are you celebrating?"
              options={occasions}
              otherOptions={["baptism", "business", "job", "graduation", "other"]}
              handleClick={handleOccasion}
            />
          </Box>
        </Slide>
      )}
      {step === "design" && (
        <Grow in={true} mountOnEnter unmountOnExit timeout={500}>
          <Box>
            <MultiSelectQuestion
              title="Let's pick a cake design"
              subtitle="Search through 1000s of popular designs or upload your own design"
              options={designOptions}
              handleClick={handleDesign}
            />
          </Box>
        </Grow>
      )}
      {step === "lifestage" && (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
          <Box>
            <MultiSelectQuestion title="Who's birthday is it?" options={birthdayOptions} handleClick={handleLifestage} />
          </Box>
        </Slide>
      )}
      {step === "theme" && (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
          <Box>
            <Themes onSubmit={handleTheme} />
          </Box>
        </Slide>
      )}
      {step === "upload" && (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
          <Paper>
            <Box px={2} py={6}>
              <Typography variant="h4" gutterBottom>
                Add Inspiration Photos
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Share photos showing design elements & color themes you're looking for
              </Typography>
              <Box my={4} px={4}>
                <Images pics={pics} edit={true} onChange={(images) => setPics(images)} folder={`img/r`} limit={10} />
              </Box>
              <Box px={2}>
                <Button color="primary" variant="contained" onClick={() => onSubmit("", pics)} disabled={pics.length === 0} fullWidth>
                  Continue
                </Button>
              </Box>
            </Box>
          </Paper>
        </Slide>
      )}
      {step === "search" && (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
          <Box>
            <SearchDesigns tags={tags} occasion={occasion} onSubmit={onSubmit} />
          </Box>
        </Slide>
      )}
    </Box>
  );
}
