import React, { useState, useEffect } from "react";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { CAKE_SERVINGS, TREAT_SERVINGS } from "../constants";

export default function ProductServings(props) {
  const { productType } = props;
  const [servings, setServings] = useState("");

  useEffect(() => {
    props.onChange(productType, servings);
  }, [servings]);

  let OPTIONS = [];
  if (productType === "Cake") {
    OPTIONS = CAKE_SERVINGS;
  } else {
    OPTIONS = TREAT_SERVINGS;
  }

  return (
    <Box my={4}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={4}>
          <Typography variant="h6">{productType}</Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="servings-label">Servings</InputLabel>
            <Select
              value={servings}
              labelId="servings-label"
              label={`Servings`}
              id="servings"
              name="servings"
              onChange={(e) => setServings(e.target.value)}
              required
            >
              {OPTIONS.map((i) => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
