import React, { useContext, useState, useEffect } from "react";
import { Box, Button, Container, Grid, Link, LinearProgress, Paper, Typography } from "@mui/material";
import bkstApi from "../../common/api";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import ConfigContext from "../../common/context/ConfigContext";
import { AppStateContext } from "../../common/context/AppStateContext";
import { IMG_PLACEHOLDER, STORAGE_BUCKET } from "../../common/constants";

export default function Classes() {
  const siteConfig = useContext(ConfigContext);
  const { cart } = useContext(AppStateContext);

  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    bkstApi(`/site/${siteConfig.id}/classes`)
      .then((res) => {
        let sortedByRank = res.data.products.sort((o1, o2) => {
          let rslt = (+o2.rank || 0) - (+o1.rank || 0);
          if (rslt === 0) {
            rslt = o1.title > o2.title ? 1 : -1;
          }
          return rslt;
        });
        setProducts(sortedByRank);
        setLoading(false);

        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="lg">
        <Box py={6}>
          <Box mb={4}>
            <Typography variant="h1">Classes</Typography>
          </Box>
          <Box>
            <Grid container spacing={4}>
              {products.map((product, idx) => {
                return (
                  <Grid key={idx} item sm={4} xs={12}>
                    <Paper elevation={0}>
                      <Box p={2}>
                        <img
                          src={product.images[0] || `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`}
                          style={{ width: "100%", aspectRatio: "1", objectFit: "cover" }}
                        />
                        <Box py={2}>
                          <Typography variant="h5">{product.title}</Typography>
                          <Box
                            style={{
                              color: "#777",
                              fontSize: ".9rem",
                              overflow: "hidden",
                              display: "-webkit-box",
                              "-webkit-line-clamp": "1",
                              "line-clamp": "1",
                              "-webkit-box-orient": "vertical"
                            }}
                            dangerouslySetInnerHTML={{
                              __html: product.description
                            }}
                          />
                        </Box>

                        <Button fullWidth variant="contained" color="primary" component={RouterLink} to={`/class/${product.id}`}>
                          VIEW Details
                        </Button>
                      </Box>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Container>
    );
  }
}
