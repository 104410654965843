import React, { useState } from "react";

export const AppStateContext = React.createContext();

export default ({ children }) => {
  const [cart, setCart] = useState("");

  const updateCartState = (cartId, quantity) => {
    if (cart && cart.id === cartId) {
      quantity += cart.quantity;
    }

    setCart({ id: cartId, quantity });
  };

  const setCartState = (cartId, quantity) => {
    setCart({ id: cartId, quantity });
  };

  const resetCartState = () => {
    setCart("");
  };

  return <AppStateContext.Provider value={{ cart, setCartState, updateCartState, resetCartState }}>{children}</AppStateContext.Provider>;
};
