import React from "react";
import { Box, Typography } from "@mui/material";
import { REFUND_POLICY } from "../../common/constants";

export default function OrderRefundPolicy(props) {
  return (
    <Box my={2} id="refund-policy">
      <Typography variant="subtitle1">Returns & Refund policy</Typography>
      <Typography variant="caption" color="textSecondary">
        {REFUND_POLICY}
      </Typography>
    </Box>
  );
}
