import React, { useContext, useState, useEffect, useRef } from "react";
import { Avatar, Box, Button, Chip, Grid, LinearProgress, Link, Paper, Typography } from "@mui/material";
import bkstApi from "../../common/api";
import Product from "../../common/component/Product";
import { useNavigate, useLocation } from "react-router-dom";
import TagSearchBox from "./TagSearchBox";
import { Link as RouterLink } from "react-router-dom";
import ConfigContext from "../../common/context/ConfigContext";
import { STORAGE_BUCKET } from "../../common/constants";

export default function SearchDesigns(props) {
  const ref = useRef(null);
  const siteConfig = useContext(ConfigContext);

  let location = useLocation();
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState("");
  const [tags, setTags] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location.hash.startsWith("#search/")) {
      const token = location.hash.split("/")[1];
      setKeyword(token.replace("-", " "));
    }
  }, [location.hash]);

  useEffect(() => {
    if (keyword) {
      callApi(keyword);
    }
  }, [keyword]);

  const search = (keyword) => {
    navigate(`#search/${keyword.replace(/\s/g, "-")}`);
  };

  const sortPhotos = (o1, o2) => {
    if (o1.rank !== o2.rank) {
      return o2.rank - o1.rank;
    } else {
      // sort random
      return 0.5 - Math.random();
    }
  };

  const callApi = (query) => {
    setLoading(true);
    setResults([]);

    bkstApi(`/site/${siteConfig.id}/photo?q=${query}&type=cake&occasion=${props.occasion}`).then((res) => {
      setResults(res.data.results.sort(sortPhotos));
      setTags(res.data.tags);
      setLoading(false);

      window.scrollTo(0, 100);
    });
  };

  const tagChips = (
    <Box my={1}>
      {tags &&
        tags.map((o, idx) => {
          return (
            <Chip
              key={idx}
              sx={{ margin: "2px" }}
              size="small"
              color="secondary"
              variant="outlined"
              label={o}
              clickable
              component={RouterLink}
              to={`#search/${o.replace(/\s/g, "-")}`}
            />
          );
        })}
    </Box>
  );

  const searchForm = (
    <Box>
      <TagSearchBox onSubmit={search} keyword={keyword} />
    </Box>
  );

  return (
    <Paper my={4} py={4} ref={ref} sx={{ scrollMargin: "30px" }}>
      {loading && <LinearProgress />}
      {!loading && results.length > 0 && (
        <Box>
          <Box pt={6} px={2} pb={1}>
            <Box>
              <Grid container>
                <Grid item sm={3} xs={12}></Grid>
                <Grid item sm={6} xs={12}>
                  {searchForm}
                  {tagChips}
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box px={2}>
            <Box my={2} sx={{ display: "flex", gap: "5px", alignItems: "center", justifyContent: "center" }}>
              <img src={`${STORAGE_BUCKET}/img/logo/powered-by-google.png`} style={{ height: "12px" }} />
              <Typography variant="caption" color="textSecondary">
                Results may include photos from other websites
              </Typography>
            </Box>
            <Box pb={10}>
              <Grid container spacing={4}>
                {results.map((value, index) => {
                  let icon = `${STORAGE_BUCKET}/img/b/${siteConfig.id}/logo400.webp`;
                  if (value.src === "exp") {
                    icon = `${STORAGE_BUCKET}/img/icon/goog.png`;
                  } else if (value.src === "goog") {
                    icon = `${STORAGE_BUCKET}/img/icon/goog.jpg`;
                  }
                  return (
                    <Grid item xs={12} sm={4} key={index} textAlign="center">
                      <Box sx={{ position: "relative" }}>
                        <Avatar style={{ width: "24px", height: "24px", position: "absolute", top: 5, right: 5 }} src={icon} />
                        <Product img={value.pic}>
                          <Button size="large" variant="contained" fullWidth onClick={() => props.onSubmit(keyword, [value.pic])} disableElevation>
                            Select & Customize
                          </Button>
                        </Product>
                      </Box>
                      <Box>
                        <Typography color="textSecondary" variant="caption" display="block" gutterBottom>
                          You can customize color, decor, tiers...
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
      {!loading && results.length === 0 && (
        <Box mt={5}>
          <Paper variant="outlined">
            <Box px={2} pt={6} pb={10}>
              <Box mb={4}>
                <Typography variant="h3" gutterBottom>
                  Uh oh, nothing found!
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Try searching for a different theme
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  or{" "}
                  <Link component={RouterLink} to="#upload" underline="always">
                    upload your design
                  </Link>
                </Typography>
              </Box>
              {tagChips}
              <Grid container>
                <Grid item sm={4} xs={12}></Grid>
                <Grid item sm={4} xs={12}>
                  {searchForm}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      )}
    </Paper>
  );
}
