import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useNavigate } from "react-router";
import ConfigContext from "../../common/context/ConfigContext";
import bkstApi from "../../common/api";
import StaticPage from "../../common/screen/StaticPage";
import { Link as RouterLink } from "react-router-dom";

export default function Unsubscribe(props) {
  const navigate = useNavigate();
  const siteConfig = useContext(ConfigContext);

  const { quoteId } = useParams();

  const [reason, setReason] = useState("");
  const [text, setText] = useState("");

  const [status, setStatus] = useState("loading");

  const onSubmit = (e) => {
    e.preventDefault();

    const payload = {
      reason,
      text
    };
    bkstApi.post(`/site/${siteConfig.id}/feedback/quote/${quoteId}`, payload).then((res) => {
      setStatus("done");
    });
  };
  if (status === "done") {
    return (
      <StaticPage title="Thank you!" desc="We appreciate your feedback" icon={CheckCircleOutlineOutlinedIcon}></StaticPage>
    );
  } else {
    return (
      <Container maxWidth="sm">
        <form onSubmit={onSubmit}>
          <Box mt={6} mb={4} align="center">
            <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
              Help us improve
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary">
              What's the main reason you aren't ordering from us?
            </Typography>
          </Box>
          <Box my={2}>
            <Button color="secondary" variant="outlined" fullWidth component={RouterLink} to={`/quote/${quoteId}`}>
              VIEW YOUR QUOTE
            </Button>
          </Box>
          <Paper elevation={0}>
            <Box px={2} py={2}>
              <RadioGroup name="reason" value={reason} onChange={(e) => setReason(e.target.value)}>
                <FormControlLabel value="plan" control={<Radio required={true} />} label="Change of plans" />
                <FormControlLabel value="price" control={<Radio required={true} />} label="Found a cheaper option elsewhere" />
                <FormControlLabel value="fulfillment" control={<Radio required={true} />} label="No delivery option" />
                <FormControlLabel value="other" control={<Radio required={true} />} label="Something else" />
              </RadioGroup>
              {reason && (
                <Box py={2}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      value={text}
                      variant="outlined"
                      fullWidth
                      id="text"
                      label="Anything to add"
                      name="text"
                      multiline
                      rows={2}
                      required={reason === "other"}
                      onChange={(e) => setText(e.target.value)}
                    />
                  </FormControl>
                </Box>
              )}
            </Box>
          </Paper>
          <Box my={2} textAlign={"center"}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              UNSUBSCRIBE
            </Button>
            <Box my={1}>
              <Typography color="textSecondary" variant="caption">
                Clicking the button above will close your quote request. You will no longer receive any communication about this quote request.
              </Typography>
            </Box>
          </Box>
        </form>
      </Container>
    );
  }
}
