import React, { useContext, useState, useEffect } from "react";
import { Box, LinearProgress } from "@mui/material";
import bkstApi from "../api";
import QuestionAnswer from "./QuestionAnswer";
import ConfigContext from "../context/ConfigContext";

export default function Faq() {
  const siteConfig = useContext(ConfigContext);

  const [faqs, setFaqs] = useState();

  useEffect(() => {
    bkstApi(`/site/${siteConfig.id}/faq`).then((res) => {
      setFaqs(res.data);
    });
  }, []);

  if (faqs) {
    return (
      <Box>
        {faqs.map((o, idx) => {
          return <QuestionAnswer key={idx} question={o.question} answer={o.answer} />;
        })}
      </Box>
    );
  } else {
    return <LinearProgress />;
  }
}
