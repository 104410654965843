import React, { useState } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import ProductServings from "../../common/component/ProductServings";

export default function AskServings(props) {
  const { data } = props;
  const [items, setItems] = useState(data.items);

  const onSubmit = (e) => {
    e.preventDefault();

    props.onSubmit({
      servings: generateNotes(),
      items
    });
  };

  const generateNotes = () => {
    let arr = [];
    for (const key in items) {
      const value = items[key];
      if (value && value.servings) {
        if (key === "Cake") {
          arr.push(`${value.servings} servings ${key}`);
        } else {
          arr.push(`${value.servings} ${key}`);
        }
      }
    }

    return arr.join(", ");
  };

  const onChange = (productType, servings) => {
    console.log("ASK : onChange", productType, servings);
    let tmp = { ...items };
    tmp[productType] = { servings };
    setItems(tmp);
  };

  return (
    <Paper>
      <Box px={2} py={7}>
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            How many servings?
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Consider the number of guests you plan to serve
          </Typography>
        </Box>
        <form onSubmit={onSubmit}>
          <Box px={2} my={4}>
            {Object.keys(data.items).map((item, idx) => {
              return <ProductServings key={idx} productType={item} onChange={onChange} />;
            })}
          </Box>
          <Button size="large" color="primary" variant="contained" type="submit" fullWidth>
            Continue
          </Button>
        </form>
      </Box>
    </Paper>
  );
}
