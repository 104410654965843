import React from "react";
import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

export default function OrderCareInstructions(props) {
  return (
    <Box>
      <Typography variant="overline" color="textSecondary">
        Care Instructions
      </Typography>
      <Alert severity="info" icon={<VisibilityOutlinedIcon />}>
        <AlertTitle>Inspect</AlertTitle>
        Inspect your cake at pickup/delivery to ensure everything is in order. Your cake may contain hidden, inedible items like dowels, metal &
        styrofoam for decor & support. Be sure to remove them before serving.
      </Alert>
      <Box my={1}>
        <Alert severity="info" icon={<AssignmentOutlinedIcon />}>
          <AlertTitle>Handle with care</AlertTitle>
          Carry your cake with both hands at the bottom of the box. Keep your cake level at all times. Transport on a flat surface in your vehicle,
          like the front floor board or the trunk.
        </Alert>
      </Box>
      <Alert severity="info" icon={<AcUnitOutlinedIcon />}>
        <AlertTitle>Keep it cool</AlertTitle>
        Keep your cake away from sunlight, heat & moisture. Transport in an air-conditioned vehicle. Store in a refrigerator. Best served at room
        temperature.
      </Alert>
    </Box>
  );
}
