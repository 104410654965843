import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";

export default function QuestionAnswer(props) {
  return (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
        <Typography>{props.question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography color="textSecondary">{props.answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
