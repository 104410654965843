import React from "react";
import { Alert, Box, Grid, Link, Paper, Typography } from "@mui/material";
import moment from "moment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { capitalize, getCustomerFirstNameLastInitial, getCustomerName } from "../../common/util";

export default function OrderFulfillment(props) {
  const { order } = props;

  if (order.type === "booking") {
    return (
      <Box my={4}>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Typography variant="overline" color="textSecondary">
              WHERE
            </Typography>
            <Paper elevation={0}>
              <Box p={2}>
                <Typography variant="body1" gutterBottom>
                  {order.fulfillment.location.street}
                  {order.fulfillment.location.unit && `, ${order.fulfillment.location.unit}`}
                </Typography>
                <Typography variant="body1">
                  {order.fulfillment.location.city}, {order.fulfillment.location.state} {order.fulfillment.location.zip}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="overline" color="textSecondary">
              WHEN
            </Typography>
            <Paper elevation={0}>
              <Box p={2}>
                <Typography variant="body1" gutterBottom>
                  {moment(order.fulfillment.date, "YYYY-MM-DD").format("ddd, MMM D, YYYY")}
                </Typography>
                <Typography variant="body1">{order.fulfillment.time}</Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return (
      <Box>
        <Typography variant="overline" color="textSecondary">
          Fulfillment
        </Typography>
        <Paper elevation={0}>
          <Box px={2} py={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  {capitalize(order.fulfillment.type)} address
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {order.fulfillment?.location && (
                  <Box>
                    <Typography variant="body2" gutterBottom>
                      {order.fulfillment.location.street}
                      {order.fulfillment.location.unit && `, ${order.fulfillment.location.unit}`}
                    </Typography>
                    <Typography variant="body2">
                      {order.fulfillment.location.city}, {order.fulfillment.location.state} {order.fulfillment.location.zip}
                    </Typography>
                    {order.fulfillment.type === "pickup" && (
                      <Link
                        component="a"
                        underline="always"
                        target="_blank"
                        sx={{ fontSize: "12px" }}
                        href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(
                          `${order.fulfillment.location.street}, ${order.fulfillment.location.city}, ${order.fulfillment.location.state} ${order.fulfillment.location.zip}`
                        )}`}
                      >
                        Get Directions
                      </Link>
                    )}
                  </Box>
                )}
                {!order.fulfillment.location && (
                  <Box>
                    <Typography variant="body2">{order.fulfillment.address}</Typography>
                    {order.fulfillment.type === "pickup" && (
                      <Link
                        component="a"
                        underline="always"
                        target="_blank"
                        href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(
                          order.fulfillment.address
                        )}`}
                      >
                        Get directions
                      </Link>
                    )}
                  </Box>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  {capitalize(order.fulfillment.type)} date & time
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{`${moment(order.fulfillment.date).format("ddd, MMM DD, YYYY")} [${order.fulfillment.time}]`}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  {capitalize(order.fulfillment.type)} instructions
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {order.fulfillment.type === "pickup" && (
                  <Box>
                    <Box mb={0.5}>
                      <Typography color="textSecondary" variant="caption" gutterBottom>
                        When you arrive for pickup, please share the following information with the store clerk.
                      </Typography>
                    </Box>
                    <Typography variant="body2">
                      Pickup Code: {order.customer.phone?.slice(-4)} • Name: {getCustomerFirstNameLastInitial(getCustomerName(order.customer))}
                    </Typography>
                  </Box>
                )}
                <Typography variant="body2">{order.fulfillment.instructions}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    );
  }
}
