import React, { useEffect, useRef } from "react";
import { Box, Button, Container, Paper, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function StaticPage(props) {
  const Icon = props.icon;
  const ref = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      ref.current.scrollIntoView();
    }, 500);
  }, []);

  return (
    <Container maxWidth="sm" align="center">
      <Box py={6} px={2} ref={ref}>
        <Paper elevation={0}>
          <Box py={6} px={2}>
            <Icon style={{ fontSize: "7rem" }} />
            <Typography variant="h3" color="textPrimary" gutterBottom>
              {props.title}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {props.desc}
            </Typography>
            {props.href && (
              <Box py={3}>
                <Button component={RouterLink} variant="contained" color="primary" size="large" to={props.href}>
                  {props.btnText}
                </Button>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
