import { motion, useInView, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";

export default function Stagger(props) {
  const { children, idx } = props;

  const ref = useRef(null);
  const isInview = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (isInview) {
      controls.start("visible");
    }
  }, [isInview]);

  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, translateX: -50, translateY: -50 },
        visible: { opacity: 1, translateX: 0, translateY: 0 }
      }}
      transition={{ duration: 0.3, delay: idx * 0.1 }}
      initial="hidden"
      animate={controls}
    >
      {children}
    </motion.div>
  );
}
