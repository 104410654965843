import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

export default function PhoneField(props) {
  const { value } = props;

  return (
    <PhoneInput
      country={"us"}
      value={value}
      onChange={(phone) => props.onChange(`+${phone}`)}
      inputClass="phone"
      containerClass="phone"
      containerStyle={props.style || {}}
      specialLabel={props.name}
      countryCodeEditable={false}
    />
  );
}
