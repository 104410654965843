import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import PaymentStatus from "../component/PaymentStatus";
import { loadStripe } from "@stripe/stripe-js";

const key = process.env.REACT_APP_STRIPE_KEY;
const promise = loadStripe(key);

export default function PaymentStatusPage(props) {
  return (
    <Elements stripe={promise}>
      <PaymentStatus />
    </Elements>
  );
}
