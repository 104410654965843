import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { EventOutlined, LocalAtmOutlined, PinDropOutlined } from "@mui/icons-material/";
import Images from "../../common/component/image/Images";
import IconText from "../../common/component/IconText";
import moment from "moment";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { generateServingsSnippet, getAddress } from "../../common/util";

export default function RequestSummary(props) {
  const { request } = props;

  let servings = generateServingsSnippet(props.request.servings);
  const address = getAddress(request.eventLocation) || request.location;

  return (
    <Paper elevation={0} style={{ backgroundColor: "#ECECEC" }}>
      <Box px={2} py={3}>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={5}>
            <Images showArrows={true} pics={props.request.pics} />
          </Grid>
          <Grid item sm={8} xs={7}>
            <Typography variant="h5">{props.request.title}</Typography>
            <Box mt={1} style={{ color: "#666" }}>
              <IconText icon={EventOutlined} text={`${moment(props.request.date).format("ddd, MMM D, YYYY")}`} />
              {address && <IconText icon={PinDropOutlined} text={address} />}
              {props.request.budget && <IconText icon={LocalAtmOutlined} text={`$${props.request.budget} Budget`} />}
              <IconText icon={ShoppingBagOutlinedIcon} text={servings} />
            </Box>
          </Grid>
          {props.request.details && props.request.details.trim() && (
            <Grid item sm={12} xs={12}>
              <Box>
                <Typography variant="body2" color="textSecondary">
                  <b>Notes</b>: {props.request.details}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
}
