import React from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import currency from "currency.js";

export default function OrderCancelationPolicy(props) {
  const { date, total, tip, delivery, ts } = props;
  let policy = "";

  if (date && total && ts) {
    const advanceBookingDays = moment(date).diff(moment(ts), "days");

    if (advanceBookingDays >= 22) {
      policy += `If you cancel by ${moment(date).subtract(22, "days").format("MMM D, YYYY")}, your cancellation fee will be ${currency(
        0.25 * total
      ).format()}. `;
    }

    if (advanceBookingDays >= 8) {
      policy += `If you cancel by ${moment(date).subtract(8, "days").format("MMM D, YYYY")}, your cancellation fee will be ${currency(
        0.5 * total
      ).format()}. If you cancel after ${moment(date).subtract(8, "days").format("MMM D, YYYY")}, your cancellation fee will be ${currency(
        total - tip - delivery
      ).format()}. `;
    } else {
      policy += `If you cancel by ${moment(date).format("MMM D, YYYY")}, your cancellation fee will be ${currency(
        total - tip - delivery
      ).format()}. `;
    }
  }

  return (
    <Box>
      <Box id="cancelation-policy">
        <Typography variant="subtitle1">Order Cancellation policy</Typography>
        <Box>
          <Typography variant="caption" color="textSecondary">
            A cancellation fee is applicable when you cancel your order. The cancellation fee is based on when you cancel and the total amount of your
            order. The cancellation fee rises as you move closer to the order due date.
          </Typography>
        </Box>
        <Box my={1}>
          <Typography variant="caption" color="textSecondary">
            {policy}
          </Typography>
        </Box>
        <Box my={1}>
          <Typography variant="caption" color="textSecondary">
            The cancellation fee will be deducted from the amount you paid and the balance amount will be refunded to the original form of payment.
            Refunds can take up to 5 business days. 
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
