export const STORAGE_BUCKET = "https://storage.googleapis.com/bakestreet-f55a0.appspot.com";
export const IMG_PLACEHOLDER = "/images/placeholder.png";
export const BKST_LOGO = "/images/logo/logo_200x43.png";

export const TIME_WINDOWS = [
  "8am - 9am",
  "9am - 10am",
  "10am - 11am",
  "11am - Noon",
  "Noon - 1pm",
  "1pm - 2pm",
  "2pm - 3pm",
  "3pm - 4pm",
  "4pm - 5pm",
  "5pm - 6pm",
  "6pm - 7pm",
  "7pm - 8pm"
];

export const REFUND_POLICY =
  "All custom orders are handcrafted, made-to-order and perishable. Each product is hand-made and some design variation should be expected. We ask that you closely inspect your order during pickup or delivery. You are solely responsible for any damage to the items thereafter. Custom cakes and treats are very fragile and damage can happen if not handled properly during transportation & setup. Damage may also happen due to exposure to excessive heat & humidity, which may result in the appearance of cracks, smudges, melted frosting, colors changes and other structural & cosmetic issues. We cannot be held liable for any damage when the items are in your possession. Furthermore, due to the highly subjective nature of taste, we are unable to offer any refund if you find the taste unsatisfactory.";

export const CAKE_SERVINGS = ["8-10", "12-15", "15-20", "20-25", "25-30", "35-40", "40-45", "55-60", "65-70", "80-100"];
export const TREAT_SERVINGS = ["1 dozen", "2 dozen", "3 dozen", "4 dozen", "5 dozen"];
