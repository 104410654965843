import { useState, useEffect } from "react";
import { Box, Chip, Paper, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import TagSearchBox from "./TagSearchBox";

const tagsDb = {
  baby: [
    "baby shark",
    "boss baby",
    "cocomelon",
    "dinosaur",
    //"disney",
    "farm",
    //"fire truck",
    "frozen",
    "paw patrol",
    "peppa pig",
    "safari"
    //"spiderman"
  ],
  smallkid: [
    "astronaut",
    "avengers",
    //"basketball",
    "dinosaur",
    "fortnite",
    "frozen",
    "harry potter",
    "lego",
    "lol surprise",
    //"pokemon",
    //"star wars",
    "tik tok",
    "truck"
  ],
  teen: [
    "avengers",
    "baseball cap",
    "basketball",
    "beach",
    //"dinosaur",
    "ferrari",
    "guitar",
    "iphone",
    //"pizza",
    "roblox",
    "sneakers",
    "tik tok",
    "travel"
  ],
  adult: [
    //"basketball",
    //"bitcoin",
    //"bohemian",
    "casino",
    //"dj",
    "doctor",
    "ferrari",
    "floral",
    "handbag",
    //"fashion",
    //"heels",
    "movie & tv show",
    "geode",
    "great gatsby",
    "liquor",
    "money",
    "playstation",
    //"semi naked",
    "sneakers",
    "travel"
    //"zodiac"
  ],
  wedding: ["abstract", "bohemian", "floral", "geode", "ombre", "semi naked"],
  graduation: ["book", "doctor", "lawyer", "striped", "quilted", "dentist"],
  "baby-shower": ["angel", "balloon", "celestial", "cloud", "floral", "gender reveal", "stork"],
  anniversary: ["boho", "fashion", "floral", "heart", "liquor", "textured", "travel"],
  baptism: ["3d", "bohemian", "car", "character", "fashion", "floral", "geode", "letter", "liquor", "number", "photo"],
  business: ["doctor", "nurse", "dentist", "lawyer", "police", "dj", "painter", "photographer", "computer", "company logo", "retirement"],
  other: ["3d", "boho", "car", "character", "fashion", "floral", "geode", "letter", "liquor", "number"]
};

export default function Themes(props) {
  let location = useLocation();

  const [theme, setTheme] = useState("");
  const [tags, setTags] = useState("");

  useEffect(() => {
    if (location.hash.startsWith("#theme/")) {
      const token = location.hash.split("/")[1];
      setTheme(token);
      setTags(tagsDb[token]);
    }
  }, [location.hash]);

  const onSubmit = (value) => {
    props.onSubmit({ keyword: value, tags });
  };

  return (
    <Paper>
      <Box px={2} py={4}>
        <Box mb={4}>
          <Typography variant="h4" gutterBottom>
            Pick a theme
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Select a popular theme below or search for your own theme
          </Typography>
        </Box>
        {tags && (
          <Box>
            {tags.map((o, idx) => {
              return (
                <Chip
                  sx={{ margin: "2px" }}
                  key={idx}
                  size="medium"
                  color="primary"
                  variant="outlined"
                  label={o}
                  clickable
                  onClick={() => onSubmit(o)}
                />
              );
            })}
          </Box>
        )}
        <Box mt={4}>
          <TagSearchBox onSubmit={onSubmit} />
        </Box>
      </Box>
    </Paper>
  );
}
