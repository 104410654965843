import React, { useContext, useState, useEffect, useRef } from "react";
import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Fab, IconButton, Tooltip, Typography } from "@mui/material";
import { getQueryParam } from "../../common/util";
import bkstApi from "../../common/api";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ChatCanvas from "../../common/component/chat/ChatCanvas";
import NewMessage from "../../common/component/chat/NewMessage";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { STORAGE_BUCKET } from "../../common/constants";
import ConfigContext from "../../common/context/ConfigContext";

export default function QuoteChat(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const siteConfig = useContext(ConfigContext);

  const ref = useRef(null);

  const [chat, setChat] = useState(props.chat || []);
  const [open, setOpen] = useState(window.location.hash.includes("chat") || getQueryParam(window.location.search, "to") === props.baker.id);
  const [showTooltip, setShowTooltip] = useState(props.showTooltip);

  useEffect(() => {
    if (open) {
      bkstApi(`/request/${props.reqId}/quote/${props.baker.id}/chat`).then((res) => {
        setChat(res.data.chat || []);
      });
      setShowTooltip(false);
    }
  }, [open]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [chat]);

  const handleNewMsg = (msg) => {
    const allMsgs = [...chat, msg];
    setChat(allMsgs);
  };

  return (
    <Box display="inline">
      <Fab color="secondary" sx={{ position: "fixed", bottom: "20px", right: "20px" }} onClick={(e) => setOpen(true)}>
        <ChatBubbleOutlineIcon />
      </Fab>
      <Dialog fullScreen={fullScreen} fullWidth maxWidth={fullScreen ? false : "sm"} open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ margin: "0", padding: "10px" }}>
          <Box display="flex" alignItems="center" gap="10px">
            <Avatar src={`${STORAGE_BUCKET}/img/b/${siteConfig.id}/logo400.webp`} />
            <Typography variant="h6">{props.name}</Typography>
          </Box>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon sx={{ color: "#999" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent className="chat-bg" style={{ minHeight: "50vh", padding: "0", margin: "0", display: "flex", flexDirection: "column" }}>
          <Box mb={1} sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
            <ChatCanvas chat={chat} uid={props.uid} />
            <Box textAlign="center" ref={ref}>
              <Chip label="VIEW QUOTE" color="primary" onClick={() => setOpen(false)} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ margin: 0, border: 0, paddingBottom: "10px", paddingRight: "10px", paddingLeft: 0, paddingTop: 0 }}>
          <Box sx={{ flexGrow: "1" }}>
            <NewMessage handleNewMsg={handleNewMsg} uploadFolder={`img/r/${props.reqId}`} postUrl={`/quote/${props.reqId}/chat`} />
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
