import React from "react";
import { Box, Chip } from "@mui/material";
import ChatBubble from "./ChatBubble";
import moment from "moment";

export default function ChatCanvas(props) {
  let content = [];

  let dt = "";
  if (props.chat) {
    for (const [key, item] of Object.entries(props.chat)) {
      let newDt = moment(item.ts).format("MMM DD");
      if (newDt !== dt) {
        content.push(
          <Box mb={2} key={item.ts} textAlign="center">
            <Chip size="small" label={newDt.toUpperCase()} />
          </Box>
        );
        dt = newDt;
      }
      content.push(<ChatBubble key={key} chat={item} uid={props.uid} />);
    }
  }

  return <Box className="chat-canvas">{content}</Box>;
}
