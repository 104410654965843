import { Box, Link, Paper, Typography } from "@mui/material";
import { STORAGE_BUCKET } from "../../common/constants";
import { useTheme } from "@mui/material/styles";
import { ReactSVG } from "react-svg";

export default function Option(props) {
  const theme = useTheme();

  return (
    <Paper
      style={{
        color: theme.components.MuiButton.styleOverrides.containedPrimary.color,
        backgroundColor: theme.palette.primary.main,
        display: "inline-block",
        margin: "5px",
        minWidth: "130px",
        minHeight: "120px"
      }}
    >
      <Link underline="hover" color="inherit" onClick={props.onClick} style={{ cursor: "pointer" }}>
        <Box px={2} pt={3} pb={2} textAlign="center">
          <Box>
            <ReactSVG
              src={`/img/${props.icon}`}
              style={{ color: theme.components.MuiButton.styleOverrides.containedPrimary.color }}
              beforeInjection={(svg) => {
                svg.setAttribute("style", "height: 60px");
              }}
            />
          </Box>
          <Typography variant="overline" display="block">
            {props.title}
          </Typography>
        </Box>
      </Link>
    </Paper>
  );
}
