import React, { useState } from "react";
import { Box, Paper, Skeleton } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Product(props) {
  const [loading, setLoading] = useState(true);

  return (
    <Paper elevation={3} style={{ backgroundColor: "#eee", padding: "5px", marginBottom: "10px" }}>
      {loading && <Skeleton variant="rect" width="100%" height={350} />}
      <LazyLoadImage
        src={props.img}
        style={window.screen.width <= 480 ? { height: "auto", width: "100%" } : { height: "250px", width: "100%", objectFit: "cover" }}
        threshold={600}
        afterLoad={() => {
          setLoading(false);
        }}
      />
      {!loading && props.children}
    </Paper>
  );
}
