import React from "react";
import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import Images from "../../common/component/image/Images";
import HTMLViewer from "../../common/component/HTMLViewer";
import LineItem from "../../common/component/LineItem";

export default function OrderDetails(props) {
  const { order } = props;
  const details = order.details;

  if (order.items) {
    return (
      <Paper elevation={0}>
        <Box px={2} pt={3} pb={1}>
          {order.items.map((item, idx) => (
            <Box key={idx} mb={1}>
              <LineItem item={item} view="min" />
            </Box>
          ))}
        </Box>
      </Paper>
    );
  } else {
    return (
      <Paper elevation={0}>
        <Box px={2} py={2}>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={3}>
              <Images pics={details.pics} allowDownload={props.allowDownload} />
            </Grid>
            <Grid item sm={8} xs={9}>
              <Typography variant="h5" gutterBottom>
                {details.title}
              </Typography>
              <Box mt={1}>
                <Grid container style={{ lineHeight: "2" }}>
                  {details.size && (
                    <Grid item xs={12}>
                      <Typography variant="overline" color="textSecondary">
                        SIZE:{" "}
                      </Typography>
                      <Typography variant="body2" display="inline">
                        {`${details.shape || ""} ${details.size}`}
                      </Typography>
                    </Grid>
                  )}
                  {details.servings && (
                    <Grid item xs={12}>
                      <Typography variant="overline" color="textSecondary">
                        SERVINGS:{" "}
                      </Typography>
                      <Typography variant="body2" display="inline">
                        {details.servings}
                      </Typography>
                    </Grid>
                  )}
                  {details.frosting && (
                    <Grid item xs={12}>
                      <Typography variant="overline" color="textSecondary">
                        FROSTING:{" "}
                      </Typography>
                      <Typography variant="body2" display="inline">
                        {details.frosting}
                      </Typography>
                    </Grid>
                  )}
                  {details.flavor && (
                    <Grid item xs={12}>
                      <Typography variant="overline" color="textSecondary">
                        FLAVOR:{" "}
                      </Typography>
                      <Typography variant="body2" display="inline">
                        {details.flavor}
                      </Typography>
                    </Grid>
                  )}
                  {details.filling && (
                    <Grid item xs={12}>
                      <Typography variant="overline" color="textSecondary">
                        FILLING:{" "}
                      </Typography>
                      <Typography variant="body2" display="inline">
                        {details.filling}
                      </Typography>
                    </Grid>
                  )}
                  {details.inscription && (
                    <Grid item xs={12}>
                      <Typography variant="overline" color="textSecondary">
                        INSCRIPTION:{" "}
                      </Typography>
                      <Typography variant="body2" display="inline">
                        {details.inscription}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Box my={1}>{details.tags && details.tags.map((e, idx) => <Chip key={idx} size="small" label={e} />)}</Box>
              </Box>
            </Grid>
            {details.decor && (
              <Grid item xs={12}>
                <Box pt={1} sx={{ borderTop: "1px solid #ECECEC" }}>
                  <HTMLViewer content={details.decor} style={{ fontSize: ".85rem" }} />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
    );
  }
}
