import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert, Box, Container, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, Typography } from "@mui/material";
import bkstApi from "../../common/api";
import ConfigContext from "../../common/context/ConfigContext";
import { getBookingMetadata } from "../../common/util";
import ImageCarousel from "../../common/component/image/ImageCarousel";
import BDatePicker from "../../common/component/BDatePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import RequestToBook from "../../common/component/RequestToBook";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import moment from "moment";

export default function RentalPage() {
  const siteConfig = useContext(ConfigContext);

  const [product, setProduct] = useState("");
  const { productId } = useParams();

  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [stage, setStage] = useState("loading");

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setStage("loading");
    bkstApi(`/site/${siteConfig.id}/rental/${productId}`).then((res) => {
      setProduct(res.data.product);

      window.scrollTo(0, 100);
      setStage("loaded");
    });
  }, [productId]);

  const validate = () => {
    let errors = [];
    if (!date) {
      errors.push("Date is missing");
    }
    if (!startTime) {
      errors.push("Start time is missing");
    }
    if (!endTime) {
      errors.push("End time is missing");
    }
    if (startTime && endTime) {
      const metadata = getBookingMetadata(product);

      const d1 = moment(startTime, "ha");
      const d2 = moment(endTime, "ha");
      if (d2.diff(d1, "hours") < +metadata.duration) {
        errors.push(`Min rental duration is ${metadata.duration} hours`);
      }
    }

    setErrors(errors);
    return errors.length === 0;
  };

  const submitInquiry = (contactInfo) => {
    bkstApi
      .post(`/site/${siteConfig.id}/product/${productId}/inquiry`, {
        title: product.title,
        variant: { date, startTime, endTime },
        customer: contactInfo
      })
      .then((res) => {
        setDate("");
        setStartTime("");
        setEndTime("");
        //updateCartState(res.data.cartId, quantity);

        setStage("done");
      });
  };

  if (stage === "loading") {
    return <LinearProgress />;
  } else {
    const metadata = getBookingMetadata(product);

    return (
      <Box>
        <Container maxWidth="lg">
          <Box my={10}>
            <Grid container spacing={6}>
              <Grid item sm={7} xs={12}>
                <ImageCarousel images={product.images} style={{ width: "100%", borderRadius: "4px" }} />
              </Grid>
              <Grid item sm={5} xs={12}>
                <Paper elevation={0}>
                  <Box px={3} py={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box>
                          <Typography variant="h3" gutterBottom>
                            {product.title}
                          </Typography>
                          <Typography variant="body2">{product.rate}</Typography>
                        </Box>
                        <Box my={2} py={2} sx={{ borderTop: "1px solid #DDD", borderBottom: "1px solid #DDD" }}>
                          {metadata.duration && (
                            <Box my={0.75}>
                              <Typography style={{ display: "flex", alignItems: "center", fontSize: "1em" }}>
                                <AccessTimeIcon style={{ fontSize: "1.2em", marginRight: "5px" }} />
                                {metadata.duration} hours min
                              </Typography>
                            </Box>
                          )}
                          {metadata.capacity && (
                            <Box my={0.75}>
                              <Typography style={{ display: "flex", alignItems: "center", fontSize: "1em" }}>
                                <FaceOutlinedIcon style={{ fontSize: "1.2em", marginRight: "5px" }} />
                                {metadata.capacity} people max
                              </Typography>
                            </Box>
                          )}
                          {metadata.area && (
                            <Box my={0.75}>
                              <Typography style={{ display: "flex", alignItems: "center", fontSize: "1em" }}>
                                <SquareFootIcon style={{ fontSize: "1.2em", marginRight: "5px" }} />
                                {metadata.area} square feet
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <div
                          style={{ color: "#777" }}
                          dangerouslySetInnerHTML={{
                            __html: product.description
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                          <BDatePicker
                            value={date || null}
                            label={`Date`}
                            onChange={(d) => setDate(d)}
                            disablePast
                            required
                            //shouldDisableDate={(d) => shouldDisableDate(d, dateRules, location)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="startTime-label">{`Start Time`}</InputLabel>
                          <Select
                            variant="outlined"
                            value={startTime}
                            labelId="startTime-label"
                            label="Start Time"
                            id="startTime"
                            name="startTime"
                            onChange={(e) => setStartTime(e.target.value)}
                          >
                            {product.options.timeslots.map((i) => (
                              <MenuItem key={i} value={i}>
                                {i}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="endTime-label">{`End Time`}</InputLabel>
                          <Select
                            variant="outlined"
                            value={endTime}
                            labelId="endTime-label"
                            label="End Time"
                            id="endTime"
                            name="endTime"
                            onChange={(e) => setEndTime(e.target.value)}
                          >
                            {product.options.timeslots.map((i) => (
                              <MenuItem key={i} value={i}>
                                {i}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        {errors?.length > 0 && (
                          <Box mb={2}>
                            <Alert severity="error">{errors.join(". ")}</Alert>
                          </Box>
                        )}
                        {stage === "done" && <Alert severity="success">We've received your inquiry and we'll be in touch shortly.</Alert>}
                        <Box my={2}>
                          <RequestToBook validate={validate} onSubmit={(payload) => submitInquiry(payload)} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    );
  }
}
