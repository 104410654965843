import React from "react";
import { Box, Paper, Rating, Typography } from "@mui/material";

export default function ReviewRatingQuestion(props) {
  return (
    <Paper elevation={0} style={{ marginBottom: "1rem" }}>
      <Box p={2}>
        <Typography variant="h6" gutterBottom>
          {props.heading}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {props.subtitle}
        </Typography>
        <Box pt={1}>
          <Rating name={props.heading} value={props.rating} size="large" onChange={props.onChange} />
        </Box>
      </Box>
    </Paper>
  );
}
