import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Faq from "../component/Faq";

export default function FaqPage(props) {
  return (
    <Container maxWidth="sm">
      <Box mt={6} mb={2}>
        <Typography variant="h1">FAQs</Typography>
      </Box>
      <Faq />
    </Container>
  );
}
