import React, { useState } from "react";
import { Badge, Box, IconButton, Link, Paper, Typography } from "@mui/material";
import { STORAGE_BUCKET } from "../constants";
import { useTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function MultiSelectOption(props) {
  const theme = useTheme();
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    const newVal = !checked;
    props.handleClick(props.title, newVal);

    setChecked(newVal);
  };

  return (
    <Badge
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      overlap="circular"
      badgeContent={
        checked ? (
          <IconButton size="small">
            <CheckCircleIcon sx={{ color: "#1abc9c" }} />
          </IconButton>
        ) : (
          0
        )
      }
    >
      <Paper
        style={{
          color: props.multiple ? "#000000" : "#FFFFFF",
          backgroundColor: props.multiple ? "#ECECEC" : theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          display: "inline-block",
          margin: "5px",
          minWidth: "130px",
          minHeight: "120px"
        }}
      >
        <Link underline="hover" color="inherit" onClick={handleClick} style={{ cursor: "pointer" }}>
          <Box px={2} pt={3} pb={2} textAlign="center">
            <img src={`${STORAGE_BUCKET}/img/icon/${props.icon}`} style={{ height: "50px" }} />
            <Typography variant="overline" display="block">
              {props.title}
            </Typography>
          </Box>
        </Link>
      </Paper>
    </Badge>
  );
}
