import React from "react";
import { Box, Grid, Link, Popover, Typography } from "@mui/material";
import { formatPhoneNumber, getStoreAddress } from "../util";

export default function StoreLocation(props) {
  const { store, index } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `${index}-hours` : undefined;

  return (
    <Box sx={{ backgroundColor: "#F7F7F7", borderRadius: "4px" }} mb={2} p={2}>
      <Box>
        <Box>
          <Typography variant="body1">{store.address.street}</Typography>
          <Typography variant="body1">
            {store.address.city}, {store.address.state} {store.address.zip}
          </Typography>
          <Box my={0.4}>
            <Typography variant="body2" color="textSecondary">
              {formatPhoneNumber(store.phone)}
            </Typography>
          </Box>
          <Box>
            <Link
              underline="always"
              color="inherit"
              component="a"
              href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(getStoreAddress(store))}`}
              target="_blank"
            >
              Directions
            </Link>
            &nbsp;&bull;&nbsp;
            <Link underline="always" color="inherit" href={`tel:${store.phone}`}>
              Call
            </Link>
            &nbsp;&bull;&nbsp;
            <Link underline="always" color="inherit" onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ cursor: "pointer" }}>
              Hours
            </Link>
          </Box>
        </Box>
      </Box>
      {store.hours?.length > 0 && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
        >
          <Box p={2} sx={{ width: "180px" }}>
            {store.hours.map((o, idx) => (
              <Box key={idx} my={1}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="overline">{o.day}:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="overline">{o.hours}</Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        </Popover>
      )}
    </Box>
  );
}
